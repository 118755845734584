import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import List from '@mui/material/List';
import TextField from '@mui/material/TextField';
import React, { ChangeEvent, useState } from 'react';
import { useParams } from 'react-router-dom';

import DispositionCard from '~components/DispositionCard';
import EmptyState from '~components/EmptyState';
import { useNotification } from '~providers/NotificationProvider';
import { APIError, UnsupportedStructureError } from '~services/Errors';

import { createCampaignDisposition, deleteCampaignDispositionByCode, updateCampaignDisposition } from '../../api';
import { CampaignType, Disposition, UpdateDisposition } from '../../domain';
import CreateEditDispositionModal from './CreateEditDispositionModal';
import DispositionDetailsModal from './DispositionDetailsModal';

interface Query {
  search: string;
}

interface CampaignDispositionsProps {
  dispositions: Disposition[];
  campaignType: CampaignType;
  triggerCampaignRefresh: () => Promise<void>;
}

// Guard for checking if object is type of interface CreateDisposition
const isCreateDisposition = (item: Disposition | UpdateDisposition): item is Disposition => {
  return (item as Disposition).code !== undefined;
};

// Guard for checking if object is type of interface UpdateDisposition
const isUpdateDisposition = (item: Disposition | UpdateDisposition): item is UpdateDisposition => {
  return (item as UpdateDisposition).originalDispositionCode !== undefined;
};

const CampaignDispositions = ({ dispositions, campaignType, triggerCampaignRefresh }: CampaignDispositionsProps) => {
  const { campaignId } = useParams() as { campaignId: string };
  const { pushNotification } = useNotification();
  const [createDispositionModalOpen, setCreateDispositionModalOpen] = useState<boolean>(false);
  // TODO: potential derived state bug
  const [selectedDispsition, setSelectedDisposition] = useState<Disposition | undefined>(undefined);
  // TODO: potential derived state bug
  const [editableDisposition, setEditableDisposition] = useState<Disposition | undefined>(undefined);
  const [submittingData, setSubmittingData] = useState<boolean>(false);
  const [query, setQuery] = useState<Query>({
    search: '',
  });

  const onQueryChange = (e: ChangeEvent<any>) => {
    const { name, value } = e.target;
    setQuery((prev) => ({ ...prev, [name]: value }));
  };

  const openViewModal = (disposition: Disposition) => () => {
    setSelectedDisposition(disposition);
  };
  const closeViewModal = () => {
    setSelectedDisposition(undefined);
  };

  const openCreateModal = () => {
    setCreateDispositionModalOpen(true);
  };

  const openEditModal = (disposition: Disposition) => () => {
    setEditableDisposition(disposition);
  };

  const closeCreateEditModal = () => {
    setCreateDispositionModalOpen(false);
    setEditableDisposition(undefined);
  };

  const addDisposition = async (data: Disposition) => {
    setSubmittingData(true);

    const name = data.title;

    try {
      await createCampaignDisposition(+campaignId, data);
    } catch (e) {
      pushNotification('error', (e as APIError | UnsupportedStructureError).message);

      // Modal catches error to prevent form reset on create failure
      return Promise.reject();
    } finally {
      setSubmittingData(false);
    }

    pushNotification('success', `Created Disposition ${name}`);

    // TODO: do we need to even await, parent component handles state display errors if this refresh fails
    await triggerCampaignRefresh();
    setCreateDispositionModalOpen(false);
  };

  const updateDisposition = async (data: UpdateDisposition) => {
    setSubmittingData(true);

    const name = data.disposition.title;

    try {
      await updateCampaignDisposition(+campaignId, data);
    } catch (e) {
      pushNotification('error', (e as APIError | UnsupportedStructureError).message);

      // Modal catches error to prevent form reset on create failure
      return Promise.reject();
    } finally {
      setSubmittingData(false);
    }

    pushNotification('success', `Updated Disposition ${name}`);

    // TODO: do we need to even await, parent component handles state display errors if this refresh fails
    await triggerCampaignRefresh();
    closeCreateEditModal();
  };

  const onCreateEditAccept = async (data: Disposition | UpdateDisposition) => {
    if (isCreateDisposition(data)) {
      await addDisposition(data);
    }

    if (isUpdateDisposition(data)) {
      await updateDisposition(data);
    }
  };

  const removeDisposition = (code: string, subCode: string, name: string) => async () => {
    try {
      await deleteCampaignDispositionByCode(+campaignId, code, subCode);
    } catch (e) {
      pushNotification('error', (e as APIError | UnsupportedStructureError).message);

      // Modal catches error to prevent form reset on create failure
      return Promise.reject();
    }

    pushNotification('success', `Removed Disposition ${name}`);

    // TODO: do we need to even await, parent component handles state display errors if this refresh fails
    await triggerCampaignRefresh();
  };

  const displayList: any = dispositions
    // Search Fuzzy matching on top level disposition/ sub dispositions
    // TODO: search on code/ sub code and remove sub disposition search criteria
    .filter((item: any) => {
      if (query.search) {
        const searchQuery = query.search.toLowerCase();
        return item.title.toLowerCase().includes(searchQuery);
      }

      return true;
    })
    // Component generation
    .map((item, index) => (
      <DispositionCard
        key={index}
        code={item.code}
        subCode={item.subCode}
        title={item.title}
        outcome={item.outcome}
        canDelete={dispositions.length > 1}
        onEdit={openEditModal(item)}
        onClick={openViewModal(item)}
        onDelete={removeDisposition(item.code, item.subCode, item.title)}
      />
    ));

  // If any filter property is set and displayList is empty we assume no relevant search results
  const noSearchOrFilterResults = query.search && displayList.length === 0;

  return (
    <>
      <Grid container spacing={1} alignContent='center'>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            variant='outlined'
            label='Search'
            id='search'
            name='search'
            defaultValue={query.search}
            onChange={onQueryChange}
          />
        </Grid>

        <Hidden smDown>
          <Grid item md={5}></Grid>
        </Hidden>

        <Grid style={{ display: 'flex', alignItems: 'center' }} item xs={12} md={3}>
          <Button
            variant='contained'
            color='primary'
            disableElevation
            fullWidth
            startIcon={<AddIcon />}
            onClick={openCreateModal}>
            Add Disposition
          </Button>
        </Grid>

        <Grid item xs={12}>
          {!noSearchOrFilterResults && <List>{displayList}</List>}
          {noSearchOrFilterResults && (
            <EmptyState
              type='no-records-found'
              text='No dispositions found matching your search criteria'
              subText='Try alternate words or selections.'
            />
          )}
        </Grid>
      </Grid>

      <DispositionDetailsModal
        open={Boolean(selectedDispsition)}
        disposition={selectedDispsition}
        onClose={closeViewModal}
      />

      <CreateEditDispositionModal
        open={createDispositionModalOpen || Boolean(editableDisposition)}
        campaignType={campaignType}
        disposition={editableDisposition}
        submitting={submittingData}
        onClose={closeCreateEditModal}
        onAccept={onCreateEditAccept}
      />
    </>
  );
};

export default CampaignDispositions;
