import * as j from '@mojotech/json-type-validation';

export const enum ChannelType {
  WebChat = 'webchat',
  SMS = 'sms',
  Email = 'email',
  AndroidRCS = 'android-rcs',
  AppleBusinessChat = 'apple-business-chat',
}

export type ChannelStats = {
  channel: ChannelType;
  conversationsInQueue: number;
  conversationsAbandonedInQueue: number;
  averageWaitTimeSec: number;
  averageHandlingTimeSec: number;
  averageFirstResponseTimeSec: number;
};

export type QueueStats = {
  queue: string;
  title: string;
  channel: ChannelType;
  conversationsInQueue: number;
  conversationsAbandonedInQueue: number;
  averageWaitTimeSec: number;
  averageHandlingTimeSec: number;
  averageFirstResponseTimeSec: number;
};

export type AgentStats = {
  username: string;
  name: string;
  state: string;
  stateTimestamp: string;
  maxConcurrency: number;
  desiredConcurrency: number;
  currentConcurrency: number;
  averageHandlingTimeSec: number;
  averageFirstResponseTimeSec: number;
  transferQueueCount: number;
  abandonedConversations: number;
  queues: string[];
  channels: string[];
};

export type ConversationStats = {
  conversationId: number;
  conversationUUID: string;
  queue: string;
  queueTitle: string;
  channelType: string;
  customerName: string;
  agentQueue: string | null;
  agentName: string | null;
  agentUsername: string | null;
  createdTimestamp: string;
  dequeuedTimestamp: string | null;
  status: string;
  waitTimeSec: number;
  handlingTimeSec: number | null;
  firstResponseTimeSec: number | null;
};

export type AsyncAllStats = {
  // key is channel id
  channels: { [key: string]: ChannelStats };
  // key is queue id
  queues: { [key: string]: QueueStats };
  // key is agent username
  agents: { [key: string]: AgentStats };
  // key is conversation id
  conversations: { [key: string]: ConversationStats };
};

const ChannelStatsDecoder: j.Decoder<ChannelStats> = j
  .object({
    channel: j.string(),
    conversations_in_queue: j.number(),
    conversations_abandoned_in_queue: j.number(),
    average_wait_time_sec: j.number(),
    average_handling_time_sec: j.number(),
    average_first_response_time_sec: j.number(),
  })
  .map((item) => ({
    channel: item.channel as ChannelType,
    conversationsInQueue: item.conversations_in_queue,
    conversationsAbandonedInQueue: item.conversations_abandoned_in_queue,
    averageWaitTimeSec: item.average_wait_time_sec,
    averageHandlingTimeSec: item.average_handling_time_sec,
    averageFirstResponseTimeSec: item.average_first_response_time_sec,
  }));

const QueueStatsDecoder: j.Decoder<QueueStats> = j
  .object({
    queue: j.string(),
    title: j.string(),
    channel: j.string(),
    conversations_in_queue: j.number(),
    conversations_abandoned_in_queue: j.number(),
    average_wait_time_sec: j.number(),
    average_handling_time_sec: j.number(),
    average_first_response_time_sec: j.number(),
  })
  .map((item) => ({
    queue: item.queue,
    title: item.title,
    channel: item.channel as ChannelType,
    conversationsInQueue: item.conversations_in_queue,
    conversationsAbandonedInQueue: item.conversations_abandoned_in_queue,
    averageWaitTimeSec: item.average_wait_time_sec,
    averageHandlingTimeSec: item.average_handling_time_sec,
    averageFirstResponseTimeSec: item.average_first_response_time_sec,
  }));

const AgentStatsDecoder: j.Decoder<AgentStats> = j
  .object({
    username: j.string(),
    name: j.string(),
    state: j.string(),
    state_timestamp: j.string(),
    max_concurrency: j.number(),
    desired_concurrency: j.number(),
    current_concurrency: j.number(),
    average_handling_time_sec: j.number(),
    average_first_response_time_sec: j.number(),
    transfer_queue_count: j.number(),
    abandoned_conversations: j.number(),
    queues: j.array(j.string()),
    channels: j.array(j.string()),
  })
  .map((item) => ({
    username: item.username,
    name: item.name,
    state: item.state,
    stateTimestamp: item.state_timestamp,
    maxConcurrency: item.max_concurrency,
    desiredConcurrency: item.desired_concurrency,
    currentConcurrency: item.current_concurrency,
    averageHandlingTimeSec: item.average_handling_time_sec,
    averageFirstResponseTimeSec: item.average_first_response_time_sec,
    transferQueueCount: item.transfer_queue_count,
    abandonedConversations: item.abandoned_conversations,
    queues: item.queues,
    channels: item.channels,
  }));

const ConversationStatsDecoder: j.Decoder<ConversationStats> = j
  .object({
    conversation_id: j.number(),
    conversation_uuid: j.string(),
    queue: j.string(),
    queue_title: j.string(),
    channel_type: j.string(),
    customer_name: j.string(),
    agent_queue: j.union(j.string(), j.constant(null)),
    agent_name: j.union(j.string(), j.constant(null)),
    agent_username: j.union(j.string(), j.constant(null)),
    created_timestamp: j.string(),
    dequeued_timestamp: j.union(j.string(), j.constant(null)),
    status: j.string(),
    wait_time_sec: j.number(),
    handling_time_sec: j.union(j.number(), j.constant(null)),
    first_response_time_sec: j.union(j.number(), j.constant(null)),
  })
  .map((item) => ({
    conversationId: item.conversation_id,
    conversationUUID: item.conversation_uuid,
    queue: item.queue,
    queueTitle: item.queue_title,
    channelType: item.channel_type,
    customerName: item.customer_name,
    agentQueue: item.agent_queue,
    agentName: item.agent_name,
    agentUsername: item.agent_username,
    createdTimestamp: item.created_timestamp,
    dequeuedTimestamp: item.dequeued_timestamp,
    status: item.status,
    waitTimeSec: item.wait_time_sec,
    handlingTimeSec: item.handling_time_sec,
    firstResponseTimeSec: item.first_response_time_sec,
  }));

export const AsyncAllStatsDecoder: j.Decoder<AsyncAllStats> = j
  .object({
    channels: j.union(j.dict(ChannelStatsDecoder), j.constant(null)),
    queues: j.union(j.dict(QueueStatsDecoder), j.constant(null)),
    agents: j.union(j.dict(AgentStatsDecoder), j.constant(null)),
    conversations: j.union(j.dict(ConversationStatsDecoder), j.constant(null)),
  })
  .map((item) => ({
    channels: item.channels || {},
    queues: item.queues || {},
    agents: item.agents || {},
    conversations: item.conversations || {},
  }));
