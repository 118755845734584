import LoadingButton from '@mui/lab/LoadingButton';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import useTheme from '@mui/material/styles/useTheme';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { DateTimePicker } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import React, { ChangeEvent, FocusEvent, FormEvent, useEffect, useMemo, useState } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';

import { DataItem } from '~components/DataItem';
import { DotLoader } from '~components/DotLoader';
import SectionCard from '~components/SectionCard';
import useDebounce from '~hooks/useDebounce';
import useForm, { ValidatorType } from '~hooks/useForm';
import { moveLeadsListCampaign, updateLeadsListById } from '~pages/CampaignManagement/api';
import useCampaignSearch from '~pages/CampaignManagement/CampaignList/useCampaignSearch';
import { CampaignListItem, LeadList, UpdateLeadListGeneralSettings } from '~pages/CampaignManagement/domain';
import { useAuth } from '~providers/AuthProvider';
import { AccessScope } from '~providers/AuthProvider/domain';
import { useNotification } from '~providers/NotificationProvider';
import Routes from '~providers/RouteProvider/Routes';
import { APIError, UnsupportedStructureError } from '~services/Errors';

const enum EditType {
  General = 'general',
}

interface LeadListSettingsProps {
  leadList: LeadList;
  triggerLeadListRefresh: () => Promise<void>;
}

const DATE_FORMAT = 'FFF';

const formatDate = (date: string, formatString: string) => {
  if (date) {
    return DateTime.fromISO(date).toFormat(formatString);
  }
  return '-';
};

const LeadListSettings = ({ leadList, triggerLeadListRefresh }: LeadListSettingsProps) => {
  const theme = useTheme();
  const { hasScope } = useAuth();
  const navigate = useNavigate();
  const { campaignId, listId } = useParams() as { campaignId: string; listId: string };
  const { pushNotification } = useNotification();
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const [edit, setEdit] = useState<EditType | undefined>(undefined);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [submittingMove, setSubmittingMove] = useState<boolean>(false);
  const [moveToCampaignId, setMoveToCampaignId] = useState<number | undefined>(undefined);
  const [campaignSearch, setCampaignSearch] = useState<string>('');
  const debouncedCampaignSearch = useDebounce(campaignSearch, 500);
  const [endTimeValidationError, setEndTimeValidationError] = useState<string | undefined>(undefined);
  const {
    loading: campaignFetching,
    error: campaignFetchError,
    list: campaigns,
    intersectionObserverRef: lastCampaignDataElement,
  } = useCampaignSearch(debouncedCampaignSearch, { archived: false });
  const {
    fields,
    errors,
    handleInputChange,
    handleUnconventionalInputChange,
    handleSubmit,
    addSchemaProperties,
    removeSchemaProperties,
    setAsyncFields,
  } = useForm({
    name: {
      validators: [{ type: ValidatorType.Required, message: 'List name is required.' }],
      value: leadList.name,
    },
    description: {
      validators: [{ type: ValidatorType.Required, message: 'List description is required.' }],
      value: leadList.description,
    },
    priority: {
      validators: [{ type: ValidatorType.Required, message: 'List priority is required.' }],
      value: leadList.priority,
    },
    setStartEndTime: {
      validators: [],
      value: false,
    },
    isActive: {
      validators: [],
      value: leadList.isActive,
    },
  });
  const fieldsDateToMinDate = useMemo(
    () => (fields.startTime === undefined ? undefined : DateTime.fromISO(fields.startTime.value)),
    [fields.startTime],
  );

  // Manages optional start and end time properties within schema
  useEffect(() => {
    if (fields.setStartEndTime.value === true) {
      addSchemaProperties({
        startTime: {
          validators: [],
          value: leadList.startTime || null,
        },
        endTime: {
          validators: [],
          value: leadList.endTime || null,
        },
      });

      return;
    }

    removeSchemaProperties(['startTime', 'endTime']);
    setEndTimeValidationError(undefined);
  }, [fields.setStartEndTime.value]);

  // Evaluates local form based proeprties based on dynamic values when the
  // particular form is opened
  useEffect(() => {
    if (edit === EditType.General) {
      setAsyncFields({
        setStartEndTime: Boolean(leadList.startTime) || Boolean(leadList.endTime),
      });
    }
  }, [edit]);

  useEffect(() => {
    if (!campaignFetching && !campaignFetching) {
      // set initial value to current campaign
      setMoveToCampaignId(+campaignId);
    }
  }, [campaignFetching, campaignFetchError]);

  const onMoveToCampaignChange = (data: CampaignListItem | null) => {
    setMoveToCampaignId(data?.id || undefined);
  };

  const onMoveToCampaignSubmit = async (e?: FormEvent<HTMLFormElement>) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    if (moveToCampaignId === undefined || moveToCampaignId === +campaignId) {
      return;
    }
    setSubmittingMove(true);
    try {
      await moveLeadsListCampaign(moveToCampaignId, +listId);
    } catch (e) {
      if ((e as APIError).status === 400) {
        pushNotification('error', 'Unable to move only list in campaign or default list. Create a new list first.');
        return;
      }

      pushNotification('error', (e as APIError | UnsupportedStructureError).message);
      return;
    } finally {
      setSubmittingMove(false);
    }
    pushNotification('success', 'You have successfully moved the list.');
    navigate(`${generatePath(Routes.viewCampaign.path, { campaignId: moveToCampaignId.toString() })}?show=LeadLists`);
  };

  const toggleEdit = (value: EditType) => () => {
    setEdit((prev) => (prev === value ? undefined : value));
  };

  // Hack of applying <DateTime> generic type on the DatePicker components as then it
  // inforces correct typing for this onChange function so it's typing is not messed up. This breaks visible
  // typing however even though it passed type checking. i.e. defers TDate generic from input value which is wrong
  // as minDate then also overrides this with its DateTime generic
  const handleDateTimeChange = (fieldName: string) => (date: DateTime | null) => {
    handleUnconventionalInputChange(fieldName, date);
  };

  const onGeneralSettingsSubmit = handleSubmit(
    async (formData: UpdateLeadListGeneralSettings & { setStartEndTime: boolean }) => {
      setSubmitting(true);
      const { setStartEndTime, ...data } = formData;

      // If this is false we want to set start and end time properties as undefined
      // else we want to define them as undefined if null
      if (!setStartEndTime) {
        data.startTime = undefined;
        data.endTime = undefined;
      } else {
        data.startTime = data.startTime || undefined;
        data.endTime = data.endTime || undefined;
      }

      try {
        await updateLeadsListById(+campaignId, +listId, data);
      } catch (e) {
        pushNotification('error', (e as APIError | UnsupportedStructureError).message);
        return;
      } finally {
        setSubmitting(false);
      }

      pushNotification('success', 'You have successfully updated the list.');
      await triggerLeadListRefresh();
      toggleEdit(EditType.General)();
    },
  );

  const onCampaignSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCampaignSearch(e.target.value);
  };

  const onCampaignSearchBlur = (e: FocusEvent<HTMLInputElement>) => {
    setCampaignSearch('');
  };

  const campaignNoOptionsText = useMemo(() => {
    if (campaignFetching) {
      return <DotLoader align='center' />;
    }

    if (campaignFetchError) {
      return (
        <Typography variant='body2' align='center' color='textSecondary'>
          Failed to load campaigns
        </Typography>
      );
    }

    return undefined;
  }, [campaignFetching, campaignFetchError]);

  const filteredCampaignsList = useMemo(() => {
    if (!moveToCampaignId) {
      return campaigns;
    }

    return campaigns.filter((listItem) => {
      return listItem.id !== moveToCampaignId;
    });
  }, [campaigns, moveToCampaignId]);

  return (
    <>
      <SectionCard
        title='General'
        onEdit={hasScope(AccessScope.CanUpdateLeadsListGeneralSettings) ? toggleEdit(EditType.General) : undefined}>
        {edit !== EditType.General && (
          <>
            <DataItem stacked={isExtraSmall} disableMargin title='Is active' value={leadList.isActive ? 'Yes' : 'No'} />
            <DataItem stacked={isExtraSmall} title='List name' value={leadList?.name} />
            <DataItem stacked={isExtraSmall} title='Description' value={leadList?.description} />
            <DataItem stacked={isExtraSmall} title='List priority' value={leadList?.priority} />
            <DataItem stacked={isExtraSmall} title='Start time' value={formatDate(leadList.startTime, DATE_FORMAT)} />
            <DataItem stacked={isExtraSmall} title='End time' value={formatDate(leadList.endTime, DATE_FORMAT)} />
          </>
        )}

        {edit === EditType.General && (
          <form onSubmit={onGeneralSettingsSubmit} noValidate>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  value='top'
                  disabled={submitting}
                  control={
                    <Switch
                      id='isActive'
                      name='isActive'
                      color='primary'
                      checked={fields.isActive.value}
                      onChange={handleInputChange}
                    />
                  }
                  label='Is active?'
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  variant='outlined'
                  id='name'
                  name='name'
                  label='List name'
                  disabled={submitting}
                  required={true}
                  defaultValue={fields.name.value}
                  error={Boolean(errors.name)}
                  helperText={errors.name && errors.name[0]!}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  variant='outlined'
                  id='description'
                  name='description'
                  label='List description'
                  disabled={submitting}
                  required={true}
                  defaultValue={fields.description.value}
                  error={Boolean(errors.description)}
                  helperText={errors.description && errors.description[0]!}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  type='number'
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{ inputProps: { min: 1, max: 20 } }}
                  variant='outlined'
                  id='priority'
                  name='priority'
                  label='List priority'
                  disabled={submitting}
                  required={true}
                  defaultValue={fields.priority.value}
                  error={Boolean(errors.priority)}
                  helperText={errors.priority && errors.priority[0]!}
                  onChange={handleInputChange}
                />
              </Grid>

              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      id='setStartEndTime'
                      name='setStartEndTime'
                      checked={fields.setStartEndTime.value}
                      onChange={handleInputChange}
                      disabled={submitting}
                    />
                  }
                  label='Set start or end time?'
                />
              </Grid>

              {fields.setStartEndTime.value && (
                <>
                  <Grid item xs={12} md={6}>
                    <DateTimePicker
                      disableMaskedInput
                      componentsProps={{
                        actionBar: {
                          actions: ['clear'],
                        },
                      }}
                      disabled={submitting}
                      label='Start time'
                      inputFormat='dd/MM/yyyy hh:mm a'
                      value={fields.startTime?.value}
                      onChange={handleDateTimeChange('startTime')}
                      renderInput={(params) => <TextField {...params} fullWidth variant='outlined' />}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <DateTimePicker
                      disableMaskedInput
                      componentsProps={{
                        actionBar: {
                          actions: ['clear'],
                        },
                      }}
                      minDate={fieldsDateToMinDate}
                      onError={(reason) => {
                        if (reason === 'minDate') {
                          setEndTimeValidationError('List End Time should not be before Start Time.');
                          return;
                        }

                        setEndTimeValidationError(undefined);
                      }}
                      disabled={submitting}
                      label='End time'
                      inputFormat='dd/MM/yyyy hh:mm a'
                      value={fields.endTime?.value}
                      onChange={handleDateTimeChange('endTime')}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          variant='outlined'
                          error={Boolean(endTimeValidationError)}
                          helperText={endTimeValidationError}
                        />
                      )}
                    />
                  </Grid>
                </>
              )}

              <Grid sx={{ textAlign: 'right' }} item xs={12}>
                <Button onClick={toggleEdit(EditType.General)}>Cancel</Button>

                <LoadingButton
                  sx={{ marginLeft: 1 }}
                  type='submit'
                  variant='contained'
                  disableElevation
                  loading={submitting}
                  color='primary'>
                  Update
                </LoadingButton>
              </Grid>
            </Grid>
          </form>
        )}
      </SectionCard>

      {hasScope(AccessScope.CanMoveLeadsList) && (
        <SectionCard title='Move List to Different Campaign'>
          <form onSubmit={onMoveToCampaignSubmit} noValidate>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  id='moveToCampaign'
                  fullWidth
                  onChange={(e, data) => {
                    onMoveToCampaignChange(data);
                  }}
                  options={filteredCampaignsList}
                  noOptionsText={campaignNoOptionsText}
                  disabled={submitting}
                  getOptionLabel={(option) => option?.name || ''}
                  renderOption={(props, option) => (
                    <li {...props} ref={lastCampaignDataElement} key={option.id}>
                      {option.name}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Destination campaign'
                      variant='outlined'
                      required={true}
                      onBlur={onCampaignSearchBlur}
                      onChange={onCampaignSearchChange}
                    />
                  )}
                />
              </Grid>

              <Grid sx={{ textAlign: 'right' }} item xs={12} md={6}>
                <LoadingButton
                  sx={{ marginLeft: 1 }}
                  type='submit'
                  variant='contained'
                  disableElevation
                  loading={submittingMove}
                  disabled={moveToCampaignId === undefined || moveToCampaignId === +campaignId}
                  color='primary'>
                  Move List
                </LoadingButton>
              </Grid>
            </Grid>
          </form>
        </SectionCard>
      )}
    </>
  );
};

export default LeadListSettings;
