import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import blue from '@mui/material/colors/blue';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import { DateTime } from 'luxon';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import CallDirectory from '~components/CallDirectory';
import EmptyState from '~components/EmptyState';
import MuteModal from '~components/MuteModal';
import { useDebounceAction } from '~hooks/useDebounceAction';
import { usePrevious } from '~hooks/usePrevious';
import { CampaignType, DiallerType, Disposition, OutcomeType } from '~pages/CampaignManagement/domain';
import {
  postAgentMetadataUpdate,
  postCallRecordingAction,
  postContactStatus,
  postLeadAttemptOutcome,
  updateLeadAttemptStatus,
} from '~pages/Dialler/api';
import PrepareLeadModal from '~pages/Dialler/ConnectDialler/PrepareOutboundCallModal';
import OutcomeCapture, { Outcome } from '~pages/Dialler/ConnectDialler/VoiceView/OutcomeCapture';
import { AttemptStatusType, OutcomeDisposition } from '~pages/Dialler/domain';
import { useAppConfiguration } from '~providers/AppConfigurationProvider';
import { useAssignedDiallerGroup } from '~providers/AssignedDiallerGroupProvider';
import { useAttempt } from '~providers/AttemptProvider';
import { Attempt, AttemptCreationContext } from '~providers/AttemptProvider/domain';
import { useAuth } from '~providers/AuthProvider';
import { useConnect } from '~providers/ConnectProvider';
import {
  AgentAvailableStates,
  AgentErrorStates,
  AgentState,
  AgentStateType,
  ContactAttributeType,
  ContactDirection,
  ContactStateType,
} from '~providers/ConnectProvider/domain';
import { useLogRocket } from '~providers/LogRocketProvider';
import { useNotification } from '~providers/NotificationProvider';
import { useScreenShare } from '~providers/ScreenShareProvider';
import { pluraliseWord } from '~utils/Functions';

import ActionButton from '../ActionButton';
import ContactCallActionsPanel from '../ContactCallActionsPanel';
import { PrepareLeadFormData } from '../PrepareOutboundCallModal';
import AttemptDetails from './AttemptDetails';
import SystemDisposedInboundButtons from './SystemDisposedInboundButtons';

// Definition of properties that are uses to block potential rage click of actions when they are already in flight.
// These are specifically for dialler actions that have some interaction between the core-api BEFORE attempting to do
// their required action. i.e. validate a lead can still be dialed before even dialling etc
interface Actions {
  blockDial: boolean;
  blockHangUp: boolean;
}

const agentErrorStates: AgentState[] = [
  AgentErrorStates.Error,
  AgentErrorStates.AgentHungUp,
  AgentErrorStates.BadAddressAgent,
  AgentErrorStates.BadAddressCustomer,
  AgentErrorStates.Default,
  AgentErrorStates.FailedConnectAgent,
  AgentErrorStates.FailedConnectCustomer,
  AgentErrorStates.LineEngagedAgent,
  AgentErrorStates.LineEngagedCustomer,
  AgentErrorStates.MissedCallAgent,
  AgentErrorStates.MissedCallCustomer,
  AgentErrorStates.MultipleCCPWindows,
  AgentErrorStates.RealtimeCommunicationError,
];
const agentAvailableStates: AgentState[] = [
  AgentAvailableStates.Init,
  AgentAvailableStates.AfterCallWork,
  AgentAvailableStates.Busy,
  AgentAvailableStates.CallingCustomer,
  AgentAvailableStates.Dialing,
  AgentAvailableStates.Joining,
  AgentAvailableStates.PendingAvailable,
  AgentAvailableStates.PendingBusy,
];

const VoiceView = () => {
  // Provider component based hooks
  const { pushNotification } = useNotification();
  const appConfig = useAppConfiguration();
  const logRocket = useLogRocket();
  const { username: loggedInUserUsername } = useAuth();
  const screenShareService = useScreenShare();
  const { group } = useAssignedDiallerGroup();
  const { agent, currentVoiceContact: contact } = useConnect();
  const actions = useRef<Actions>({
    blockDial: false,
    blockHangUp: false,
  });
  const {
    attempt,
    startAutoDialTimer,
    clearAutoDialTimer,
    startAutoHangUpTimer,
    clearAutoHangUpTimer,
    setAttemptDialled,
    setAttemptInitiated,
    setAttemptConnected,
    setAttemptDisconnected,
    createLeadAttempt,
    getAssignedAttempt,
    fetchNextAttempt,
    fetchInboundAttempt,
    checkIfValidAttempt,
    clearAttempt,
  } = useAttempt();
  const [prepareLeadModalOpen, setPrepareLeadModalOpen] = useState<boolean>(false);
  const [callExtension, setCallExtension] = useState<string | undefined>(undefined);
  // Used to provide specific outcome type dispos for a call that's been attempted,
  // but not connected.
  // NOTE: Accepted flaw with this flag is if the page is refreshed
  //       we just return a full set of dispositions as at that point
  //       we will not know what the reason for disconnection of the lead was.
  const [callDisconnectedWithoutConnection, setCallDisconnectedWithoutConnection] = useState<boolean>(false);
  // Used to block fetching of next attempt when a manual outbound hangup event is in flight as there are some timing issues
  // with clearing the contact and connect putting us into a correct state.
  const [blockNextCall, setBlockNextCall] = useState<boolean>(false);
  const fetchLeadOnTimerRef = useDebounceAction(() => {
    clearAttempt();
  }, 15);
  const contactPopLinks = useMemo(
    () =>
      appConfig.web.contactPopLinks
        .filter((item) => item.contactType === 'voice')
        .map((item) => ({
          id: item.label,
          label: item.label,
          type: ContactAttributeType.Link,
          value: item.value,
        })),
    [appConfig.web.contactPopLinks],
  );
  const previousGroupId = usePrevious(group.diallerGroupId);
  const previousAgentStatusName = usePrevious(agent.status.name);
  const disableNewLead = appConfig.web.disableUnknownInboundLeadCreation;
  const disableContactContext = appConfig.web.disableUnknownInboundLeadCreation;
  // Resets call recording action button to false if contact is no longer there
  const callRecordingPaused = contact ? contact.isRecordingPaused : false;

  // Used to set up any init logic on mount
  useEffect(() => {
    logRocket.trackKeyValue('dialler', `${group.diallerType}`);
  }, []);

  // Checks to see if we have any assigned attempts, if so we present it to the agent
  // TODO: Observe behaviour on slower connections, if issues, we may need the Async loader and
  //       possibly convert the the useAttempt hook into a provider hook, this is so we can define it on a higher level
  //       for disabling state change when an attempt is present. As agents on slower connections might be able to change
  //       their status before this initial request has completed
  useEffect(() => {
    if (agent.status.type === AgentStateType.Offline || agent.status.type === AgentStateType.NotRoutable) {
      getAssignedAttempt();
    }
  }, []);

  // Handles agent to agent calls via a call extension
  const hasCallExtension =
    contact !== undefined &&
    contact.statusType === ContactStateType.Connected &&
    callExtension !== undefined &&
    contact.sendDTMF !== undefined;
  useEffect(() => {
    if (hasCallExtension) {
      const extension = callExtension;

      window.setTimeout(() => {
        // Extension with dtmf ending hash
        contact!.sendDTMF!(extension + '#', false);
      }, 1_000);

      setCallExtension(undefined);
      console.log(`+ Entered extension: ${extension}`);
    }
  }, [hasCallExtension]);

  // Dictates that we want to auto dial the attempt when it is manual prepared
  const canDialOutboundLead =
    attempt !== undefined &&
    attempt.creationContext === AttemptCreationContext.ManualPrepared &&
    attempt.initiated === false;
  useEffect(() => {
    if (canDialOutboundLead) {
      dialAttempt(attempt);
    }
  }, [canDialOutboundLead]);

  // Handles attempt loading
  const shouldFetchNextAttempt =
    group.diallerType !== DiallerType.SIP &&
    group.campaignType !== CampaignType.Predictive &&
    agent.status.type !== AgentStateType.NotRoutable &&
    agent.status.type !== AgentStateType.Offline &&
    agent.status.type !== AgentStateType.Error &&
    !blockNextCall &&
    attempt === undefined &&
    (contact === undefined || (contact !== undefined && contact.direction === ContactDirection.Outbound));
  const shouldFetchInboundAttempt = contact !== undefined && contact.direction === ContactDirection.Inbound;
  useEffect(() => {
    if (shouldFetchNextAttempt === true) {
      console.log('+ Fetching attempt as we have no attempt or contact');
      fetchNextAttempt(+group.diallerGroupId);
    } else if (shouldFetchInboundAttempt === true && contact !== undefined) {
      // If for any reason an inbound call drops in within the lead re-fetch countdown window we want to clear the
      // countdown event to prevent any unwanted behaviour bugs (i.e. fetching new lead when we have an inbound
      // lead presented to us).
      if (fetchLeadOnTimerRef.running === true) {
        fetchLeadOnTimerRef.clear();
      }

      console.log('+ Checking and fetching inbound attempt');
      fetchInboundAttempt(contact);
    }
  }, [shouldFetchNextAttempt, shouldFetchInboundAttempt]);

  // Used For cleanup between dialler group switching
  useEffect(() => {
    if (group.diallerGroupId !== previousGroupId) {
      clearAttempt();
    }
  }, [group.diallerGroupId]);

  // Handles Agent Metadata updating for backend event stream
  const postAgentMetadataUpdateEvent =
    contact !== undefined &&
    contact.direction === ContactDirection.Inbound &&
    contact.statusType === ContactStateType.Connecting &&
    contact.campaignId !== undefined;
  useEffect(() => {
    // If the contact is from a different campaign we want to post an agent event stream update
    if (postAgentMetadataUpdateEvent) {
      postAgentMetadataUpdate(contact?.campaignId!);
    }
  }, [postAgentMetadataUpdateEvent]);

  // Handles attempt clearing and additional error handling on attempt, agent, or contact errors
  const failedToConnectCustomerOtherAttempts =
    previousAgentStatusName === AgentErrorStates.FailedConnectCustomer &&
    contact === undefined &&
    attempt !== undefined &&
    attempt.creationContext !== AttemptCreationContext.Standard;
  const failedToConnectCustomerStandardAttempt =
    previousAgentStatusName === AgentErrorStates.FailedConnectCustomer &&
    contact === undefined &&
    attempt !== undefined &&
    attempt.creationContext === AttemptCreationContext.Standard;
  const wrongAttemptDisplayedForContact =
    attempt !== undefined &&
    attempt.isUnknownInbound === false &&
    attempt.isInboundReturnCall === false &&
    attempt.isInternalTransfer === false &&
    contact !== undefined &&
    contact.direction === ContactDirection.Inbound;
  const agentNotRoutable =
    agent.status.type === AgentStateType.NotRoutable &&
    attempt !== undefined &&
    attempt.initiated === false &&
    attempt.connected === false &&
    attempt.disconnected === false;
  const agentNotForcedAfterCAllWork =
    agent.status.type === AgentStateType.Offline &&
    attempt !== undefined &&
    attempt.initiated === false &&
    attempt.connected === false &&
    attempt.disconnected === false;
  // Covers the case where an agent will prepare an outbound lead while offline or not routable.
  // Note: This could be from a prepared manual outbound OR directory outbound dial attempt
  const attemptNotManualOutbound =
    attempt !== undefined && attempt.creationContext !== AttemptCreationContext.ManualPrepared;
  const shouldClearAttempt =
    (agentNotForcedAfterCAllWork ||
      failedToConnectCustomerOtherAttempts ||
      failedToConnectCustomerStandardAttempt ||
      wrongAttemptDisplayedForContact ||
      agentNotRoutable) &&
    attempt !== undefined &&
    attemptNotManualOutbound;
  useEffect(() => {
    if (shouldClearAttempt === true) {
      let message = '+ Clearing attempt';

      if (failedToConnectCustomerOtherAttempts === true) {
        message = '+ Clearing attempt due to failure to connect to customer';
      }

      if (failedToConnectCustomerStandardAttempt === true) {
        message =
          '+ Clearing attempt due to failure to connect to customer and marking call as disconnected without attempt';
        // Sets disconnect without connection based dispositions
        setCallDisconnectedWithoutConnection(true);
      }

      if (wrongAttemptDisplayedForContact === true) {
        message = '+ Clearing attempt due to wrong attempt being displayed for contact object';
      }

      if (agentNotRoutable === true) {
        message = '+ Clearing attempt due to agent not being routable';
      }

      if (agentNotForcedAfterCAllWork === true) {
        message = '+ Clearing attempt due to agent going offline (not valid forced after call work scenario)';
      }

      console.log(message);
      clearAttempt();
    }
  }, [shouldClearAttempt]);

  // Handles auto dial logic
  useEffect(() => {
    if (
      agent.status.type === AgentStateType.Routable &&
      contact === undefined &&
      attempt !== undefined &&
      attempt.autoDialEnabled === true &&
      attempt.disconnected === false
    ) {
      if (attempt.autoDialTimer !== undefined && attempt.autoDialRunning === false) {
        startAutoDialTimer();
        console.log('+ Starting auto dial timer');
      } else if (attempt.autoDialTimer !== undefined && attempt.autoDialRunning === true) {
        console.log('+ Auto dial in: ', attempt.autoDialTimer);

        if (attempt.autoDialTimer === 0) {
          console.log('+ Performing auto dial action');
          dialAttempt(attempt);
        }
      }
    } else if (
      attempt !== undefined &&
      attempt.autoDialEnabled === true &&
      attempt.autoDialRunning === true &&
      contact !== undefined
    ) {
      console.log('+ Auto dial action timer cleared');
      clearAutoDialTimer();
    }
  }, [attempt, contact, agent.status.type]);

  const manualOutboundHangupNoLongerInFlight =
    agent.status.type === AgentStateType.Error && agent.status.name === AgentErrorStates.FailedConnectAgent;
  const preCallDispoGoToStateNoLongerInFLight =
    agent.status.type === AgentStateType.Offline || agent.status.type === AgentStateType.NotRoutable;
  // Handles manual outbound hangup event logic
  useEffect(() => {
    if (manualOutboundHangupNoLongerInFlight || preCallDispoGoToStateNoLongerInFLight) {
      setBlockNextCall(false);
    }
  }, [manualOutboundHangupNoLongerInFlight, preCallDispoGoToStateNoLongerInFLight]);

  // Handles auto hangup logic
  useEffect(() => {
    if (
      attempt !== undefined &&
      attempt.autoHangUpEnabled === true &&
      contact !== undefined &&
      contact.direction === ContactDirection.Outbound &&
      contact.statusType === ContactStateType.Connecting
    ) {
      if (attempt.autoHangUpTimer !== undefined && attempt.autoHangUpRunning === false) {
        startAutoHangUpTimer();
        console.log('+ Starting auto hang up timer');
      } else if (attempt.autoHangUpTimer !== undefined && attempt.autoHangUpRunning === true) {
        console.log('+ Auto hang up in: ', attempt.autoHangUpTimer);

        if (attempt.autoHangUpTimer === 0) {
          console.log('+ Performing auto hang up action');
          contact.endSession();

          // Sets disconnect without connection based dispositions
          setCallDisconnectedWithoutConnection(true);
        }
      }
    } else if (
      attempt !== undefined &&
      attempt.autoHangUpEnabled === true &&
      attempt.autoHangUpRunning === true &&
      contact !== undefined &&
      contact.statusType === ContactStateType.Connected
    ) {
      console.log('+ Auto hang up action timer cleared');
      clearAutoHangUpTimer();
    }
  }, [attempt, contact]);

  // Handles attempt status updating
  const postUnknownInboundStatus =
    contact !== undefined && attempt !== undefined && attempt.isUnknownInbound === true && attempt.initiated === false;
  const postAttemptInitiatedStatus =
    attempt !== undefined &&
    attempt.dialled === true &&
    attempt.initiated === false &&
    attempt.isInternalTransfer === false &&
    attempt.isUnknownInbound === false &&
    attempt.isInboundReturnCall === false &&
    contact !== undefined &&
    contact.statusType === ContactStateType.Connecting;
  const postAttemptConnectedStatus =
    attempt !== undefined &&
    attempt.connected === false &&
    attempt.isInternalTransfer === false &&
    attempt.isUnknownInbound === false &&
    attempt.isInboundReturnCall === false &&
    contact !== undefined &&
    contact.statusType === ContactStateType.Connected;
  const postInboundCallStatus =
    attempt !== undefined &&
    attempt.initiated === false &&
    attempt.connected === false &&
    attempt.isUnknownInbound === false &&
    (attempt.isInternalTransfer === true || attempt.isInboundReturnCall === true) &&
    contact !== undefined &&
    contact.direction === ContactDirection.Inbound &&
    contact.statusType === ContactStateType.Connected;
  const postAttemptDisconnectedStatus =
    attempt !== undefined &&
    attempt.lead !== null &&
    attempt.disconnected === false &&
    contact !== undefined &&
    contact.statusType === ContactStateType.Ended;
  useEffect(() => {
    if (postUnknownInboundStatus === true && contact !== undefined) {
      if (contact.campaignId === undefined) {
        console.error('! Campaign ID is not set on inbound contact. Was this missed in the contact flow setup?');
        return;
      }

      let screenRecordingNames = undefined;
      // If the screen sharing extension is enabled add the recording names to the call
      if (screenShareService.serviceEnabled) {
        screenRecordingNames = screenShareService.recordingNames.join(',');
      }

      // For inbound contact's we get the campaignId from the contact object
      // as the contact is the initiator and sets the campaignId within the contact flow
      postContactStatus(contact.campaignId, contact.contactId, screenRecordingNames);
      // Mark the attempt as initiated
      setAttemptInitiated();
      console.log('+ Initial unknown inbound data set.');
    } else if (postAttemptInitiatedStatus === true && attempt !== undefined && contact !== undefined) {
      let screenRecordingNames = undefined;
      // If the screen sharing extension is enabled add the recording names to the call
      if (screenShareService.serviceEnabled) {
        screenRecordingNames = screenShareService.recordingNames.join(',');
      }

      if (attempt.lead === null) {
        // If the lead does not exist, we really cannot post the status in this situation as we have no campaign id
        console.warn('~ Unable to post lead as initiated, lead does not exist on attempt object.');
        return;
      }

      updateLeadAttemptStatus(
        attempt.lead?.campaignId,
        attempt.attemptId,
        AttemptStatusType.Initiated,
        contact.contactId,
        screenRecordingNames,
        undefined,
      );

      // Mark the attempt as initiated
      setAttemptInitiated();
      console.log('+ Posting that contact attempt is initiated.');
    } else if (postAttemptConnectedStatus === true && attempt !== undefined && contact !== undefined) {
      if (attempt.lead === null) {
        // If the lead does not exist, we really cannot post the status in this situation as we have no campaign id
        console.warn('~ Unable to post lead as connected, lead does not exist on attempt object.');
        return;
      }

      updateLeadAttemptStatus(
        attempt.lead.campaignId,
        attempt.attemptId,
        AttemptStatusType.Connected,
        undefined,
        undefined,
        undefined,
      );
      // Mark the attempt as connected
      setAttemptConnected();
      console.log('+ Posting that contact attempt is connected.');
    } else if (postInboundCallStatus === true && attempt !== undefined && contact !== undefined) {
      (async () => {
        let screenRecordingNames = undefined;
        // If the screen sharing extension is enabled add the recording names to the call
        if (screenShareService.serviceEnabled) {
          screenRecordingNames = screenShareService.recordingNames.join(',');
        }

        if (attempt.lead === null) {
          // If the lead does not exist, we really cannot post the status in this situation as we have no campaign id
          console.warn('~ Unable to post lead as initiated/ connected, lead does not exist on inbound attempt object.');
          return;
        }

        // TODO: maybe update logic in backend to allow setting contact id on attempt lead connected
        // as all other properties here can be set no matter what status, also saves a call and potential latency
        // bottlenecks on UI rerender
        await updateLeadAttemptStatus(
          attempt.lead.campaignId,
          attempt.attemptId,
          AttemptStatusType.Initiated,
          contact.contactId,
          screenRecordingNames,
          loggedInUserUsername,
        );

        await updateLeadAttemptStatus(
          attempt.lead.campaignId,
          attempt.attemptId,
          AttemptStatusType.Connected,
          undefined,
          undefined,
          undefined,
        );

        // Mark the attempt as initiated
        setAttemptInitiated();
        // Mark the attempt as connected
        setAttemptConnected();
        console.log('+ Posting that the inbound contact is initiated AND connected.');
      })();
    } else if (postAttemptDisconnectedStatus === true && attempt !== undefined && contact !== undefined) {
      if (attempt.lead === null) {
        // If the lead does not exist, we really cannot post the status in this situation as we have no campaign id
        console.warn('~ Unable to post lead as disconnected, lead does not exist on attempt object.');
        return;
      }

      updateLeadAttemptStatus(
        attempt.lead.campaignId,
        attempt.attemptId,
        AttemptStatusType.Disconnected,
        undefined,
        undefined,
        undefined,
      );
      // Mark the attempt as disconnected in our local state
      setAttemptDisconnected();
      console.log('+ Posting that contact attempt is disconnected.');
    }
  }, [
    postUnknownInboundStatus,
    postAttemptInitiatedStatus,
    postAttemptConnectedStatus,
    postInboundCallStatus,
    postAttemptDisconnectedStatus,
  ]);

  // Poll for new attempt every 10s if agent is available and not currently in a call
  // This flag is used to optimize polling effect reruns so that it only occurs if the boolean flag
  // changes, rather than when each dependency of this condition changes
  const shouldExecuteAttemptPolling =
    group.diallerType !== DiallerType.SIP &&
    group.campaignType !== CampaignType.Predictive &&
    fetchLeadOnTimerRef.running === false &&
    agent.status.type === AgentStateType.Routable &&
    !blockNextCall &&
    (attempt === undefined || (attempt !== undefined && contact === undefined));
  useEffect(() => {
    if (shouldExecuteAttemptPolling) {
      console.log('+ Re-fetch Lead Interval Started');
      let fetchCancelToken = axios.CancelToken.source();
      let refetchLeadIntervalRef = window.setInterval(() => {
        fetchNextAttempt(group.diallerGroupId, fetchCancelToken);
      }, 10_000);

      return function refetchLeadIntervalCleanup() {
        // Cancel request if it has already been executed
        fetchCancelToken.cancel();
        // Clear interval if its processing
        clearInterval(refetchLeadIntervalRef);
        console.log('+ Refetch Lead Interval Cleared');
      };
    }
  }, [shouldExecuteAttemptPolling]);

  // If we get an attempt we should close the modal if open
  const hasAttempt = Boolean(attempt);
  useEffect(() => {
    if (group.enableManualOutbound !== undefined && hasAttempt === true) {
      setPrepareLeadModalOpen(false);
    }
  }, [group.enableManualOutbound, hasAttempt]);

  // answer predictive calls immediately.
  useEffect(() => {
    (async () => {
      if (
        contact !== undefined &&
        contact.statusType === ContactStateType.Connecting &&
        group.diallerType !== DiallerType.SIP &&
        group.campaignType === CampaignType.Predictive
      ) {
        try {
          await contact.accept();
        } catch (e) {
          console.error('! triggerAnswer: Unable to answer call due to: ', e, contact);
          return;
        }
      }
    })();
  }, [contact]);

  const dialAttempt = async (atmp?: Attempt) => {
    if (actions.current.blockDial !== true) {
      // Mark action as blocked until its run cycle is completed
      actions.current.blockDial = true;

      if (atmp === undefined) {
        console.error('! dialAttempt: attempt does not exist');
        actions.current.blockDial = false;
        return;
      }

      if (atmp.lead === null) {
        console.error('! dialAttempt: attempt.lead does not exist');
        actions.current.blockDial = false;
        return;
      }

      if (group.campaignType !== CampaignType.Predictive) {
        console.log('+ Checking if attempt dial is still valid');
        const isValidAttempt = await checkIfValidAttempt(group.diallerGroupId, atmp.attemptId);

        if (!isValidAttempt) {
          console.log('+ Aborting attempt dial as it is no longer valid.');
          clearAttempt();
          pushNotification('error', 'Unable to dial lead as it is no longer valid. A new lead is now being fetched');
          actions.current.blockDial = false;
          return;
        }

        console.log('+ Attempt valid, proceeding to dial endpoint: ', atmp.lead.endpoint);
      }

      let outboundQueueARN = undefined;
      // We only want to get the outboundQueueARN if we have a lead object, else assume its default outbound queue
      if (atmp.lead && appConfig.extensions.connectOutboundTimezoneARNs) {
        outboundQueueARN = appConfig.extensions.connectOutboundTimezoneARNs[atmp.lead.timezone];
      }

      try {
        await agent.dial(atmp.lead.endpoint, outboundQueueARN);
      } catch (e) {
        // TODO: is invalid endpoint the only scenario this could error on?
        const outcomeDisposition: OutcomeDisposition = {
          contactId: undefined,
          dispositionCode: 'system',
          dispositionSubCode: 'invalid-endpoint',
          hasSystemIssue: false,
        };

        try {
          await postLeadAttemptOutcome(atmp.lead.campaignId, atmp.attemptId, outcomeDisposition, undefined);
        } catch (e) {
          console.error('! Unable to post attempt outcome of invalid endpoint due to: ', e);
        }

        // Start countdown and then wait for action to occur
        fetchLeadOnTimerRef.start();
        actions.current.blockDial = false;
        return;
      }

      // Mark the attempt as dialled in our local state
      setAttemptDialled();

      actions.current.blockDial = false;
    }
  };

  const triggerDialLead = async () => {
    await dialAttempt(attempt);
  };

  const prepareDirectoryLead = async (endpoint: string, callExtension?: number) => {
    try {
      await createLeadAttempt(+group.defaultCampaignId, endpoint, endpoint, endpoint, DateTime.local().zoneName);
    } catch (e) {
      console.error('! prepareLead: Unable to prepare lead due to: ', e);
      pushNotification(
        'error',
        'Unfortunately you have an uncompleted call that requires a disposition, please go online to complete before placing the next call.',
      );
      return;
    }

    if (callExtension !== undefined) {
      setCallExtension(callExtension.toString());
    }
  };

  const prepareLead = async (data: PrepareLeadFormData) => {
    try {
      await createLeadAttempt(+group.defaultCampaignId, data.endpoint, data.leadName, data.externalId, data.timezone);
    } catch (e) {
      console.error('! prepareLead: Unable to prepare lead due to: ', e);
      pushNotification('error', 'Unable to prepare lead due to system error');
      return;
    }
  };

  const triggerAnswer = async () => {
    if (contact === undefined) {
      console.error('! triggerAnswer: Contact does not exist');
      return;
    }

    if (contact.accept === undefined) {
      console.error('! triggerAnswer: contact.accept does not exist');
      return;
    }

    try {
      await contact.accept();
    } catch (e) {
      console.error('! triggerAnswer: Unable to answer call due to: ', e);
      return;
    }

    console.log('+ accepted contact');
  };

  const triggerHold = async () => {
    if (contact === undefined) {
      console.error('! triggerHold: Contact does not exist');
      return;
    }

    if (contact.putOnHold === undefined) {
      console.error('! triggerHold: contact.putOnHold does not exist');
      return;
    }

    try {
      await contact.putOnHold();
    } catch (e) {
      console.error('! triggerHold: Unable to put contact on hold due to: ', e);
      return;
    }

    console.log(`+ Contact with contact id of ${contact.contactId} has been placed on hold.`);
  };

  const triggerResume = async () => {
    if (contact === undefined) {
      console.error('! triggerResume: Contact does not exist');
      return;
    }

    if (contact.takeOffHold === undefined) {
      console.error('! triggerResume: contact.takeOffHold does not exist');
      return;
    }

    try {
      await contact.takeOffHold();
    } catch (e) {
      console.error('! triggerResume: Unable to take contact off hold due to: ', e);
      return;
    }

    console.log(`+ Call for contact with contact id of ${contact.contactId} has been resumed.`);
  };

  const triggerAgentMute = async () => {
    try {
      await agent.mute();
    } catch (e) {
      console.error('! triggerAgentMute: Unable to mute agent due to: ', e);
      return;
    }

    console.log(`+ Agent ${loggedInUserUsername} has been muted.`);
  };

  const triggerAgentUnmute = async () => {
    try {
      await agent.unmute();
    } catch (e) {
      console.error('! triggerAgentUnmute: Unable to unmute agent due to: ', e);
      return;
    }

    console.log(`+ Agent ${loggedInUserUsername} has been unmuted.`);
  };

  const onPrepareLeadModelOpen = () => {
    setPrepareLeadModalOpen(true);
  };

  const onPrepareLeadModelClose = () => {
    setPrepareLeadModalOpen(false);
  };

  const allowPreparedOutboundHangup =
    contact !== undefined &&
    contact.direction === ContactDirection.Outbound &&
    contact.statusType === ContactStateType.Connecting &&
    group.enablePreparedOutboundHangup === true &&
    attempt !== undefined &&
    attempt.creationContext === AttemptCreationContext.ManualPrepared;
  // Connection hangup if provided, else connection specific hangup
  // Note: This make the assumption that when in the connecting state there will only ever be the AGENT and the INITIAL
  // CUSTOMER connection for prepared outbound hangup.
  const triggerEndContactSession = async (
    preContactConnectionHangUp: boolean,
    connectionHangUpFunc?: () => Promise<void>,
  ) => {
    if (actions.current.blockHangUp !== true) {
      // Mark action as blocked until its run cycle is completed
      actions.current.blockHangUp = true;

      if (preContactConnectionHangUp) {
        // Block the next fetch to the next endpoint until process is finished
        setBlockNextCall(true);
      }

      if (contact === undefined) {
        console.error('! triggerEndContactSession: Contact does not exist');
        actions.current.blockHangUp = false;
        return;
      }

      // If call is a ringing prepared outbound attempt, we want to auto dispose of this attempt as well as hang
      // up and clear the contact/ attempt. If not we just want to skip this block and just hang up on the customer,
      // proceeding to the after call work flow.
      if (allowPreparedOutboundHangup === true) {
        const outcomeDisposition: OutcomeDisposition = {
          contactId: undefined,
          dispositionCode: 'system',
          dispositionSubCode: 'agent-abandoned',
          hasSystemIssue: false,
        };

        // If we fail to post the lead attempt outcome for any reason we just continue with the current action as
        // either the attempt itself no longer exists, and we should just then clear the contact, or something weird
        // is occurring in the core-api leading to this request to fail, and we don't want to prevent the agent from
        // performing a hang-up action.
        try {
          if (attempt === undefined) {
            throw new Error('Attempt does not exist');
          }

          // If the lead does not exist, we really cannot post the status in this situation as we have no campaign id
          if (attempt.lead === null) {
            throw new Error('Attempt lead does not exist');
          }

          await postLeadAttemptOutcome(attempt.lead.campaignId, attempt.attemptId, outcomeDisposition, undefined);
        } catch (e) {
          console.error(
            '! triggerEndContactSession: Unable to post attempt outcome of agent abandoned due to error: ',
            e,
          );
        }

        try {
          if (connectionHangUpFunc) {
            await connectionHangUpFunc();
          } else {
            await contact.endSession();
          }
        } catch (e) {
          // The only time this would error is if the contact was auto cleaned up by connect for some reason
          // making this call redundant, so we don't do anything
          setBlockNextCall(false);
        }

        clearAttempt();
      } else {
        try {
          if (connectionHangUpFunc) {
            await connectionHangUpFunc();
          } else {
            await contact.endSession();
          }
        } catch (e) {
          console.error('! triggerEndContactSession: Unable to end call with the contact due to: ', e);
          actions.current.blockHangUp = false;
          return;
        }
      }

      console.log('+ Call has been ended');
      actions.current.blockHangUp = false;
    }
  };

  const triggerResumeCallRecording = async () => {
    if (contact === undefined) {
      console.error('! triggerPauseRecording: Contact does not exist');
      return;
    }

    try {
      await postCallRecordingAction(contact.contactId, contact.initialContactId, 'resume');
    } catch (e) {
      console.error('! triggerPauseRecording: Unable to resume call recording due to: ', e);
      return;
    }
  };

  const triggerSuspendCallRecording = async () => {
    if (contact === undefined) {
      console.error('! triggerPauseRecording: Contact does not exist');
      return;
    }

    try {
      await postCallRecordingAction(contact.contactId, contact.initialContactId, 'suspend');
    } catch (e) {
      console.error('! triggerPauseRecording: Unable to pause call recording due to: ', e);
      return;
    }
  };

  const triggerContactComplete = async (data: Outcome, switchToStatus: string | undefined) => {
    if (attempt === undefined) {
      console.error('! triggerContactComplete: attempt does not exist');
      return;
    }

    // Only trigger if autoDialintervalRef is running
    if (attempt.autoDialRunning === true) {
      // Clears auto dial interval if lead is skipped and one is active
      clearAutoDialTimer();
      console.log('+ Auto Dial event cleared due to lead outcome posting');
    }

    const contactId = contact?.contactId || attempt.contactId;
    // There are a few scenarios where we get campaign ID from different locations:
    // - If we have a campaignId associated with the contact set in the contact flow for inbound contacts we should use
    //   that
    // - If we find a lead with a campaign id, that should get priority over the previous step
    // - If we do not have a lead AND the campaign id is not set on the connect contact we default to zero and cry
    //   because this request will fail.
    let campaignId = 0;
    if (contact !== undefined && contact.campaignId !== undefined) {
      campaignId = contact.campaignId;
    }
    if (attempt.lead !== null && attempt.lead.campaignId !== undefined) {
      campaignId = attempt.lead.campaignId;
    }

    const outcomeDisposition: OutcomeDisposition = {
      contactId: contactId,
      dispositionCode: data.dispositionCode,
      dispositionSubCode: data.dispositionSubCode,
      hasSystemIssue: data.hasSystemIssue,
      systemIssueDescription: data.systemIssueDescription,
      callback: data.callback,
      exclusionList: data.exclusionList,
      metadata: data.attributes,
    };

    try {
      await postLeadAttemptOutcome(campaignId, attempt.attemptId, outcomeDisposition, data.newLead);
    } catch (e) {
      pushNotification('error', (e as Error).message);

      // OutcomeCapture component catches error to prevent form reset on dispose failure
      return Promise.reject();
    }

    // If agent is not selecting their status for after dispo, lets clear the contact and let connect control which state
    // they go back too.
    // If agents selects their state change for dispo, lets change their state.
    // Note: Agent state changes within an After Call Work state results in connect clearing the contact, so we do not
    //       need to explicitly fire the clear event here.
    if (!switchToStatus) {
      // Let's check if forced after call work, if so no connect contact to clear lets set agent back online
      if (agent.status.type === AgentStateType.Offline) {
        // If previous state does not exist, we skip and keep the agent in an offline state, else we change them to their previous state
        if (previousAgentStatusName !== undefined) {
          if (
            agentAvailableStates.includes(previousAgentStatusName) ||
            agentErrorStates.includes(previousAgentStatusName)
          ) {
            console.log(
              `+ Previous available or error state found, setting back to available after forced after call work completion`,
            );
            agent.setOnline();
          } else {
            console.log(`+ set back to ${previousAgentStatusName} after forced after call work completion`);
            agent.changeStatus(previousAgentStatusName as unknown as string);
          }
        }
      }

      // Only cleanup contact if exists
      if (contact !== undefined) {
        try {
          await contact.complete();
        } catch (e) {
          // The only time this would error is if the contact was auto cleaned up by connect for some reason
          // making this call redundant, so we don't do anything
        }
      }
    } else {
      // Block the next fetch to the next endpoint until the state change is finished
      setBlockNextCall(true);
      console.log(`+ Agent disposed call and switched to status: ${switchToStatus}`);
      agent.changeStatus(switchToStatus);
    }

    clearAttempt();
    setCallDisconnectedWithoutConnection(false);

    // If the screen sharing extension is enabled AND we have lost the screen share connection
    // we want to initiate a full reconnect
    if (screenShareService.serviceEnabled && screenShareService.connectionLost) {
      screenShareService.reconnect();
    }

    console.log('+ Contact completed');
  };

  const triggerSystemContactComplete = async (switchToStatus: string | undefined) => {
    if (attempt === undefined) {
      console.error('! triggerSystemContactComplete: attempt does not exist');
      return;
    }

    const contactId = contact?.contactId || attempt.contactId;
    // There are a few scenarios where we get campaign ID from different locations:
    // - If we have a campaignId associated with the contact set in the contact flow for inbound contacts we should use
    //   that
    // - If we find a lead with a campaign id, that should get priority over the previous step
    // - If we do not have a lead AND the campaign id is not set on the connect contact we default to zero and cry
    //   because this request will fail.
    let campaignId = 0;
    if (contact !== undefined && contact.campaignId !== undefined) {
      campaignId = contact.campaignId;
    }
    if (attempt.lead !== null && attempt.lead.campaignId !== undefined) {
      campaignId = attempt.lead.campaignId;
    }

    const outcomeDisposition: OutcomeDisposition = {
      contactId: contactId,
      dispositionCode: 'system',
      dispositionSubCode: 'inbound',
      hasSystemIssue: false,
    };

    try {
      await postLeadAttemptOutcome(campaignId, attempt.attemptId, outcomeDisposition, undefined);
    } catch (e) {
      pushNotification('error', (e as Error).message);

      // OutcomeCapture component catches error to prevent form reset on dispose failure
      return Promise.reject();
    }

    // If agent is not selecting their status for after dispo, lets clear the contact and let connect control which state
    // they go back too.
    // If agents selects their state change for dispo, lets change their state.
    // Note: Agent state changes within an After Call Work state results in connect clearing the contact, so we do not
    //       need to explicitly fire the clear event here.
    if (!switchToStatus) {
      // Let's check if forced after call work, if so no connect contact to clear lets set agent back online
      if (agent.status.type === AgentStateType.Offline) {
        // If previous state does not exist, we skip and keep the agent in an offline state, else we change them to their previous state
        if (previousAgentStatusName !== undefined) {
          if (
            agentAvailableStates.includes(previousAgentStatusName) ||
            agentErrorStates.includes(previousAgentStatusName)
          ) {
            console.log(
              `+ Previous available or error state found, setting back to available after forced after call work completion`,
            );
            agent.setOnline();
          } else {
            console.log(`+ set back to ${previousAgentStatusName} after forced after call work completion`);
            agent.changeStatus(previousAgentStatusName as unknown as string);
          }
        }
      }

      // Only cleanup contact if exists
      if (contact !== undefined) {
        try {
          await contact.complete();
        } catch (e) {
          // The only time this would error is if the contact was auto cleaned up by connect for some reason
          // making this call redundant, so we don't do anything
        }
      }
    } else {
      console.log(`+ Agent disposed call and switched to status: ${switchToStatus}`);
      agent.changeStatus(switchToStatus);
    }

    clearAttempt();
    setCallDisconnectedWithoutConnection(false);

    // If the screen sharing extension is enabled AND we have lost the screen share connection
    // we want to initiate a full reconnect
    if (screenShareService.serviceEnabled && screenShareService.connectionLost) {
      screenShareService.reconnect();
    }

    console.log('+ Contact auto completed');
  };

  const triggerVoicemailMessage = async () => {
    if (contact === undefined) {
      console.error('! triggerVoicemailMessage: Contact does not exist');
      return;
    }

    if (group.voicemailMessageARN === undefined) {
      console.error('! triggerVoicemailMessage: Voicemail message config is not enabled');
      return;
    }

    try {
      await contact.sendToVoicemailMessage(group.voicemailMessageARN);
    } catch (e) {
      pushNotification('error', (e as unknown as Error).message);
    }
  };

  // Display handling logic
  // These properties SHOULD only be used within the jsx of this file, if you use them within useEffects/ or functions
  // you are using them wrong.

  // We want to know if this value is null or not to make desisions on if the contact is a return call
  // or a standard inbound call
  const isInbound = contact !== undefined && contact.direction === ContactDirection.Inbound;
  const isAgentOffline = agent.status.type === AgentStateType.Offline;
  const isAgentAvailable = agent.status.type === AgentStateType.Routable;
  const isAgentNotRoutable = agent.status.type === AgentStateType.NotRoutable;
  const isConnecting = contact?.statusType === ContactStateType.Connecting;
  const isAttemptUnfinished =
    attempt !== undefined &&
    attempt.lead !== null &&
    (attempt.initiated === true || attempt.connected === true || attempt.disconnected === true);
  const showLeadDetails =
    (attempt !== undefined && agent.status.type !== AgentStateType.Offline && !isAgentNotRoutable) ||
    isAttemptUnfinished;
  const showContactCallActionsPanel =
    (agent.status.type !== AgentStateType.Offline && !isAgentNotRoutable) || isAttemptUnfinished;
  const answerButtonDisabled = contact === undefined || !isConnecting || !isInbound;
  const isAttemptInitiatedWithoutContact = Boolean(
    contact === undefined && attempt !== undefined && attempt.initiated === true,
  );
  const isAttemptConnectedWithoutContact = Boolean(
    contact === undefined && attempt !== undefined && attempt.connected === true,
  );
  const isAttemptDisconnectedWithoutContact = Boolean(
    contact === undefined && attempt !== undefined && attempt.disconnected === true,
  );
  const isAttemptCallback = attempt !== undefined && attempt.lead !== null && attempt.lead.callbackId !== undefined;

  const dialActionDisabled =
    attempt === undefined ||
    contact !== undefined ||
    agent.status.type !== AgentStateType.Routable ||
    isAttemptInitiatedWithoutContact ||
    isAttemptConnectedWithoutContact ||
    isAttemptDisconnectedWithoutContact ||
    fetchLeadOnTimerRef.running;
  const hangUpActionDisabled = !allowPreparedOutboundHangup && contact?.statusType !== ContactStateType.Connected;

  let attemptDispositions: Disposition[] = [];
  if (attempt !== undefined && attempt.dispositions !== undefined) {
    if (callDisconnectedWithoutConnection === true) {
      if (attempt.lead !== null && attempt.lead.callbackId !== undefined) {
        // For a disconnected callback attempt resulting in a ring out we only allow final and callback outcome
        // dispositions
        attemptDispositions = attempt.dispositions.filter(
          (disposition) =>
            ![
              OutcomeType.NoAnswer,
              OutcomeType.AnsweringMachine,
              OutcomeType.Engaged,
              OutcomeType.Skipped,
              OutcomeType.InvalidEndpoint,
            ].includes(disposition.outcome),
        );
      } else {
        // For a disconnected attempt resulting in a ring out we only allow retry based outcome dispositions.
        // NOTE: We also allow contacted outcome dispositions in this case to support pure manual outbound scenarios.
        attemptDispositions = attempt.dispositions.filter((disposition) =>
          [
            OutcomeType.NoAnswer,
            OutcomeType.AnsweringMachine,
            OutcomeType.Engaged,
            OutcomeType.InvalidEndpoint,
            OutcomeType.Contacted,
          ].includes(disposition.outcome),
        );
      }
    } else if (
      contact === undefined &&
      attempt.initiated === false &&
      attempt.connected === false &&
      attempt.disconnected === false
    ) {
      if (attempt.lead !== null && attempt.lead.callbackId !== undefined) {
        // We only allow callback and removed outcome dispositions for pre call dispositions associated with a
        // callback attempt
        attemptDispositions = attempt.dispositions.filter((disposition) =>
          [OutcomeType.Callback, OutcomeType.Removed].includes(disposition.outcome),
        );
      } else {
        // We only allow skipped and removed outcome dispositions for pre call dispositions NOT associated with a
        // callback attempt
        attemptDispositions = attempt.dispositions.filter((disposition) =>
          [OutcomeType.Skipped, OutcomeType.Removed].includes(disposition.outcome),
        );
      }
    } else if (attempt.lead !== null && attempt.lead.callbackId !== undefined) {
      // For a successful callback attempt, we want to remove retry and pre-call dispositions
      attemptDispositions = attempt.dispositions.filter(
        (disposition) =>
          ![
            OutcomeType.NoAnswer,
            OutcomeType.AnsweringMachine,
            OutcomeType.Engaged,
            OutcomeType.Skipped,
            OutcomeType.Removed,
          ].includes(disposition.outcome),
      );
    } else {
      // We want to filter out pre call outcome dispositions (skipped and removed) if a call has been made against the
      // attempt
      attemptDispositions = attempt.dispositions.filter(
        (disposition) =>
          ![OutcomeType.Skipped, OutcomeType.Removed, OutcomeType.MissedCallback].includes(disposition.outcome),
      );
    }
  }
  // remove callback disposition if inbound number is fixed number or is connect predictive
  if (
    (isInbound && disableNewLead && attemptDispositions) ||
    (group.diallerType !== DiallerType.SIP && group.campaignType === CampaignType.Predictive)
  ) {
    attemptDispositions = attemptDispositions.filter(
      (disposition) => ![OutcomeType.Callback].includes(disposition.outcome),
    );
  }

  const disableAttemptedOutcomeSubmit =
    (contact === undefined && attempt !== undefined && Object.keys(attemptDispositions).length === 0) ||
    (contact !== undefined && contact.statusType !== ContactStateType.Ended);

  const canSystemDisposeInboundLeadAttempt =
    group.systemDisposedInboundCalls &&
    attempt !== undefined &&
    (attempt.creationContext === AttemptCreationContext.Inbound ||
      attempt.creationContext === AttemptCreationContext.UnknownInbound);

  return (
    <>
      {fetchLeadOnTimerRef.running && (
        <Alert
          sx={{ marginBottom: 2 }}
          variant='filled'
          severity='info'
          action={
            <Button
              variant='contained'
              disableElevation
              color='primary'
              onClick={() => {
                fetchLeadOnTimerRef.clear();
                clearAttempt();
              }}>
              Fetch Lead
            </Button>
          }>
          Fetching next lead in {fetchLeadOnTimerRef.counter} {pluraliseWord(fetchLeadOnTimerRef.counter!, 'second')}
          <Typography variant='caption' display='block'>
            We have disposed of this lead for you because the phone number was invalid.
          </Typography>
        </Alert>
      )}

      {isAttemptUnfinished &&
        isAttemptInitiatedWithoutContact &&
        !isAttemptConnectedWithoutContact &&
        !isAttemptDisconnectedWithoutContact &&
        !callDisconnectedWithoutConnection && (
          <Alert sx={{ marginBottom: 2 }} variant='filled' severity='info'>
            Please select a call outcome to dispose of this lead to continue dialling.
            <Typography variant='caption' display='block'>
              You have had an interaction with this lead, leading to an initiated state, however we can not find an
              associated contact.
            </Typography>
          </Alert>
        )}

      {isAttemptUnfinished &&
        isAttemptInitiatedWithoutContact &&
        isAttemptConnectedWithoutContact &&
        !isAttemptDisconnectedWithoutContact &&
        !callDisconnectedWithoutConnection && (
          <Alert sx={{ marginBottom: 2 }} variant='filled' severity='info'>
            Please select a call outcome to dispose of this lead to continue dialling.
            <Typography variant='caption' display='block'>
              You have had an interaction with this lead, leading to a connected state, however we can not find an
              associated contact.
            </Typography>
          </Alert>
        )}

      {isAttemptUnfinished && isAttemptDisconnectedWithoutContact && !callDisconnectedWithoutConnection && (
        <Alert sx={{ marginBottom: 2 }} variant='filled' severity='info'>
          Please select a call outcome to dispose of this lead to continue dialling.
          <Typography variant='caption' display='block'>
            You have had an interaction with this lead, leading to a disconnected state.
          </Typography>
        </Alert>
      )}

      {isAttemptUnfinished && callDisconnectedWithoutConnection && !isAttemptCallback && (
        <Alert sx={{ marginBottom: 2 }} variant='filled' severity='info'>
          Please select a call outcome to dispose of this lead to continue dialling.
          <Typography variant='caption' display='block'>
            We were unable to connect you with this lead.
          </Typography>
        </Alert>
      )}

      {isAttemptUnfinished && callDisconnectedWithoutConnection && isAttemptCallback && (
        <Alert sx={{ marginBottom: 2 }} variant='filled' severity='info'>
          Please select a call outcome to dispose of this lead to continue dialling.
          <Typography variant='caption' display='block'>
            We were unable to connect you with this lead to perform this callback.
          </Typography>
        </Alert>
      )}

      <Grid container spacing={2}>
        {showContactCallActionsPanel && (
          <Grid item sx={{ height: '100%', scrollBehavior: 'auto' }} xs={12} md={4}>
            <ContactCallActionsPanel
              onPrepareLead={group.enableManualOutbound ? onPrepareLeadModelOpen : undefined}
              onDial={triggerDialLead}
              onAnswer={triggerAnswer}
              onMute={triggerAgentMute}
              onHold={triggerHold}
              onResume={triggerResume}
              onHangUp={triggerEndContactSession}
              onResumeCallRecording={
                Boolean(appConfig.extensions.pauseRecordings) ? triggerResumeCallRecording : undefined
              }
              onSuspendCallRecording={
                Boolean(appConfig.extensions.pauseRecordings) ? triggerSuspendCallRecording : undefined
              }
              onVoicemailMessage={Boolean(group.voicemailMessageARN) ? triggerVoicemailMessage : undefined}
              diallerGroupName={group.name}
              contact={contact}
              isContactOnHold={Boolean(contact?.isOnHold)}
              isCallRecordingPaused={callRecordingPaused}
              isPrepareLeadDisabled={agent.status.type !== AgentStateType.Routable}
              isDialDisabled={dialActionDisabled}
              isAnswerDisabled={answerButtonDisabled}
              isVoicemailMessageDisabled={
                contact?.direction !== ContactDirection.Outbound || contact?.statusType !== ContactStateType.Connected
              }
              isMuteDisabled={contact?.statusType !== ContactStateType.Connected}
              isHoldDisabled={contact?.statusType !== ContactStateType.Connected || contact?.connections.length < 2}
              isResumeDisabled={contact?.statusType !== ContactStateType.Connected || contact?.connections.length < 2}
              isHangUpDisabled={hangUpActionDisabled}
              isResumeCallRecordingDisabled={contact?.statusType !== ContactStateType.Connected}
              isSuspendCallRecordingDisabled={contact?.statusType !== ContactStateType.Connected}
              isSoftphoneAutoAnswerEnabled={agent.softphoneAutoAnswerEnabled}
              hasAttempt={Boolean(attempt)}
            />
          </Grid>
        )}

        {(isAgentOffline || isAgentNotRoutable) && (
          <>
            {group.enableManualOutboundWhenOffline && !isAttemptUnfinished && (
              <>
                <Hidden smDown>
                  <Grid item sm={2}></Grid>
                </Hidden>

                <Grid item xs={12} sm={8}>
                  <ActionButton
                    fullWidth
                    colorRange={blue}
                    variant='contained'
                    disableElevation
                    disabled={hasAttempt}
                    onClick={onPrepareLeadModelOpen}>
                    Prepare Outbound Call
                  </ActionButton>
                </Grid>

                <Hidden smDown>
                  <Grid item sm={2}></Grid>
                </Hidden>
              </>
            )}

            {appConfig.extensions.callDirectory !== undefined && !isAttemptUnfinished && (
              <>
                <Hidden smDown>
                  <Grid item sm={2}></Grid>
                </Hidden>

                <Grid item xs={12} sm={8}>
                  <CallDirectory list={appConfig.extensions.callDirectory.list} onDial={prepareDirectoryLead} />
                </Grid>

                <Hidden smDown>
                  <Grid item sm={2}></Grid>
                </Hidden>
              </>
            )}

            {isAgentOffline &&
              appConfig.extensions.callDirectory === undefined &&
              !group.enableManualOutboundWhenOffline &&
              !isAttemptUnfinished && (
                <Grid item xs={12}>
                  <EmptyState type='offline' text='You are currently offline.' subText='Go online and accept calls!' />
                </Grid>
              )}

            {isAgentNotRoutable &&
              appConfig.extensions.callDirectory === undefined &&
              !group.enableManualOutboundWhenOffline && (
                <Grid item xs={12}>
                  <EmptyState
                    type='offline'
                    text={`You are in the state ${agent.status.name}`}
                    subText='Go online and accept calls!'
                  />
                </Grid>
              )}
          </>
        )}

        {isAgentAvailable && attempt === undefined && (
          <Grid item xs={12} md={8}>
            <EmptyState
              type='call-waiting'
              text={`Hello ${agent.name} you are online and ready to take calls.`}
              subText='Please wait for a call to drop in'
            />
          </Grid>
        )}

        {showLeadDetails && attempt !== undefined && (
          // TODO: setup attributes for accepting campaignId, attemptDispositions, attempt submit func, attempt submit disable
          // AND move outcome section INTO this component rather than a attribute, this should help with rendering issues
          <Grid item xs={12} md={8}>
            <AttemptDetails
              attempt={attempt}
              contact={contact}
              contactPopLinks={contactPopLinks}
              previewTimeSeconds={
                agent.status.type === AgentStateType.Routable && attempt.autoDialRunning
                  ? attempt.autoDialTimer
                  : undefined
              }
              disableContactContext={disableContactContext}
              outcomeSection={
                <>
                  {!canSystemDisposeInboundLeadAttempt && (
                    <>
                      <Typography fontWeight='700' variant='h6' gutterBottom>
                        Call Outcome
                      </Typography>

                      <OutcomeCapture
                        orgReference={appConfig.orgReference}
                        channelType='voice'
                        campaignId={contact?.campaignId}
                        dispositions={attemptDispositions}
                        diallingHours={attempt.diallingHours}
                        publicHolidays={attempt.publicHolidays}
                        exclusionLists={attempt.exclusionLists}
                        timezone={attempt.lead !== null ? attempt.lead.timezone : undefined}
                        agentUsername={loggedInUserUsername}
                        isUnknownLead={attempt.attemptId === 0 && attempt.lead === null}
                        contactName={attempt.lead !== null ? attempt.lead.name : undefined}
                        endpoint={attempt.lead !== null ? attempt.lead.endpoint : contact?.phoneNumber}
                        disableSubmit={disableAttemptedOutcomeSubmit || fetchLeadOnTimerRef.running}
                        disableNewLead={disableNewLead}
                        onSubmit={triggerContactComplete}
                      />
                    </>
                  )}

                  {canSystemDisposeInboundLeadAttempt && (
                    <SystemDisposedInboundButtons
                      submit={triggerSystemContactComplete}
                      disable={disableAttemptedOutcomeSubmit}
                    />
                  )}
                </>
              }
            />
          </Grid>
        )}
      </Grid>

      <MuteModal open={agent.isMuted} onClose={triggerAgentUnmute} contact={contact} />

      {(group.enableManualOutbound || group.enableManualOutboundWhenOffline) && (
        <PrepareLeadModal
          open={prepareLeadModalOpen}
          onClose={onPrepareLeadModelClose}
          onAccept={prepareLead}
          manualOutboundRequireDetails={group.manualOutboundRequireDetails}
        />
      )}
    </>
  );
};

export default VoiceView;
