import Divider from '@mui/material/Divider';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import React, { ChangeEvent, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { TabPanel } from '~components/TabPanel';
import AccessFilterList from '~pages/SystemManagement/AccessFilterList';
import { useAppConfiguration } from '~providers/AppConfigurationProvider';
import { useAuth } from '~providers/AuthProvider';
import { AccessScope } from '~providers/AuthProvider/domain';
import { useSetPageTitleProps } from '~providers/PageTitleProvider';

import AgentList from './AgentList';
import DispositionAttributesList from './DispositionAttributesList';
import ExclusionListList from './ExclusionListList';
import PredictiveAgentStatuses from './PredictiveAgentStatuses';
import PredictiveIVRMessageList from './PredictiveIVRMessageList';
import SipQueuesList from './SipQueues';
import SkillTypeList from './SkillTypeList';
import TransferList from './TransferList';

// Used to manage visible tab selection
enum ViewTabsType {
  Agents,
  SkillTypes,
  ExclusionLists,
  DispositionAttributes,
  PredictiveAgentStates,
  AccessFilters,
  Transfers,
  PredictiveIVRMessages,
  SipQueues,
  SipTrunks,
  SipContactFlows,
}

const a11yProps = (index: number) => ({
  'id': `tab-${index}`,
  'aria-controls': `tab-${index}`,
});

const SystemManagement = () => {
  const appConfig = useAppConfiguration();
  const { hasScope } = useAuth();
  const setPageTitleProps = useSetPageTitleProps();
  const [searchParams, setSearchParams] = useSearchParams({
    show: ViewTabsType[0],
  });
  const tabIndex = +ViewTabsType[searchParams.get('show') as any];

  // temporary, show it on qa only
  const displayPredictiveIvr = appConfig.extensions.predictive !== undefined;

  // Set page title
  useEffect(() => {
    setPageTitleProps({ pageName: 'System Configuration' });
  }, []);

  const onTabChange = (e: ChangeEvent<{}>, tabIndex: number): void => {
    setSearchParams({
      show: ViewTabsType[tabIndex],
    });
  };

  return (
    <>
      <Typography variant='h4' component='h1' gutterBottom>
        System Configuration
      </Typography>

      <Tabs
        orientation='horizontal'
        variant='scrollable'
        onChange={onTabChange}
        value={tabIndex}
        indicatorColor='primary'
        aria-label='Vertical tabs example'>
        <Tab label='Agents' {...a11yProps(ViewTabsType.Agents)} value={ViewTabsType.Agents} />
        <Tab label='Skill Types' {...a11yProps(ViewTabsType.SkillTypes)} value={ViewTabsType.SkillTypes} />
        <Tab label='Exclusion Lists' {...a11yProps(ViewTabsType.ExclusionLists)} value={ViewTabsType.ExclusionLists} />
        <Tab
          label='Disposition Attributes'
          {...a11yProps(ViewTabsType.DispositionAttributes)}
          value={ViewTabsType.DispositionAttributes}
        />

        {hasScope(AccessScope.CanViewAccessFilters) && (
          <Tab label='Access Filters' {...a11yProps(ViewTabsType.AccessFilters)} value={ViewTabsType.AccessFilters} />
        )}

        {hasScope(AccessScope.CanViewTransfers) && (
          <Tab label='Transfers' {...a11yProps(ViewTabsType.AccessFilters)} value={ViewTabsType.Transfers} />
        )}

        {appConfig.extensions.predictive !== undefined && (
          <Tab
            label='Predictive Agent States'
            {...a11yProps(ViewTabsType.PredictiveAgentStates)}
            value={ViewTabsType.PredictiveAgentStates}
          />
        )}
        {displayPredictiveIvr && (
          <Tab
            label='Predictive IVR Messages'
            {...a11yProps(ViewTabsType.PredictiveIVRMessages)}
            value={ViewTabsType.PredictiveIVRMessages}
          />
        )}
        {displayPredictiveIvr && (
          <Tab label='SIP Queues' {...a11yProps(ViewTabsType.SipQueues)} value={ViewTabsType.SipQueues} />
        )}
      </Tabs>
      <Divider variant='fullWidth' component='hr' />

      <TabPanel value={tabIndex} index={ViewTabsType.Agents}>
        <AgentList />
      </TabPanel>

      <TabPanel value={tabIndex} index={ViewTabsType.SkillTypes}>
        <SkillTypeList />
      </TabPanel>

      <TabPanel value={tabIndex} index={ViewTabsType.ExclusionLists}>
        <ExclusionListList />
      </TabPanel>

      <TabPanel value={tabIndex} index={ViewTabsType.DispositionAttributes}>
        <DispositionAttributesList />
      </TabPanel>

      {hasScope(AccessScope.CanViewAccessFilters) && (
        <TabPanel value={tabIndex} index={ViewTabsType.AccessFilters}>
          <AccessFilterList />
        </TabPanel>
      )}

      {hasScope(AccessScope.CanViewTransfers) && (
        <TabPanel value={tabIndex} index={ViewTabsType.Transfers}>
          <TransferList />
        </TabPanel>
      )}

      {appConfig.extensions.predictive !== undefined && (
        <TabPanel value={tabIndex} index={ViewTabsType.PredictiveAgentStates}>
          <PredictiveAgentStatuses />
        </TabPanel>
      )}

      {displayPredictiveIvr && (
        <TabPanel value={tabIndex} index={ViewTabsType.PredictiveIVRMessages}>
          <PredictiveIVRMessageList />
        </TabPanel>
      )}
      {displayPredictiveIvr && (
        <TabPanel value={tabIndex} index={ViewTabsType.SipQueues}>
          <SipQueuesList />
        </TabPanel>
      )}
    </>
  );
};

export default SystemManagement;
