import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import List from '@mui/material/List';
import TextField from '@mui/material/TextField';
import React, { ChangeEvent, useState } from 'react';
import { useParams } from 'react-router-dom';

import EmptyState from '~components/EmptyState';
import Selectbox from '~components/Form/Selectbox';
import { useAuth } from '~providers/AuthProvider';
import { AccessScope } from '~providers/AuthProvider/domain';
import { useNotification } from '~providers/NotificationProvider';
import { APIError, UnsupportedStructureError } from '~services/Errors';

import { createLeadsListForCampaign, toggleLeadsListActiveById } from '../../api';
import { CreateLeadList, LeadListItem } from '../../domain';
import CreateLeadListModal from './CreateLeadListModal';
import LeadListCard from './LeadListCard';

const enum FilterType {
  Empty = '',
  Active = 'active',
  Inactive = 'inactive',
}

interface Query {
  search: string;
  filter: FilterType;
}

interface CampaignLeadListsProps {
  list: LeadListItem[];
  triggerCampaignRefresh: () => Promise<void>;
}

const filterList = [
  {
    label: 'None',
    value: FilterType.Empty,
  },
  {
    label: 'Active',
    value: FilterType.Active,
  },
  {
    label: 'Inactive',
    value: FilterType.Inactive,
  },
];

const CampaignLeadLists = ({ list, triggerCampaignRefresh }: CampaignLeadListsProps) => {
  const { hasScope } = useAuth();
  const { pushNotification } = useNotification();
  const { campaignId } = useParams() as { campaignId: string };
  const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);
  const [createModalSubmitting, setCreateModalSubmitting] = useState<boolean>(false);
  const noLeadLists = !list || list.length === 0;
  const [query, setQuery] = useState<Query>({
    search: '',
    filter: FilterType.Empty,
  });

  const toggleCreateListModal = () => {
    setCreateModalOpen((prev) => !prev);
  };

  const onQueryChange = (e: ChangeEvent<any>) => {
    const { name, value } = e.target;
    setQuery((prev) => ({ ...prev, [name]: value }));
  };

  const addLeadList = async (data: CreateLeadList) => {
    setCreateModalSubmitting(true);

    try {
      await createLeadsListForCampaign(+campaignId, data);
    } catch (e) {
      pushNotification('error', (e as APIError | UnsupportedStructureError).message);

      // Modal catches error to prevent form reset on create failure
      return Promise.reject();
    } finally {
      setCreateModalSubmitting(false);
    }

    pushNotification('success', `Created List ${data.name}`);

    // TODO: do we need to even await, parent component handles state display errors if this refresh fails
    await triggerCampaignRefresh();
    setCreateModalOpen(false);
  };

  const toggleListActive = (listId: number) => async (checked: boolean) => {
    try {
      await toggleLeadsListActiveById(+campaignId, +listId, checked);
    } catch (e) {
      pushNotification('error', (e as APIError | UnsupportedStructureError).message);
    }

    // TODO: do we need to even await, parent component handles state display errors if this refresh fails
    await triggerCampaignRefresh();
  };

  const displayList = noLeadLists
    ? []
    : list
        // Filter by filter value
        .filter((item: LeadListItem) => {
          // Return everything if filter empty
          if (query.filter === FilterType.Empty) return true;
          if (query.filter === FilterType.Active && item.isActive) return true;
          if (query.filter === FilterType.Inactive && !item.isActive) return true;
          return false;
        })
        // Search Fuzzy matching
        .filter((item: LeadListItem) => {
          if (query.search) {
            return item.name.toLowerCase().includes(query.search.toLowerCase());
          }

          return true;
        })
        // Component generation
        .map((item: LeadListItem) => (
          <LeadListCard key={item.id} leadsList={item} onToggleList={toggleListActive(item.id)} />
        ));

  // If any filter property is set and displayList is empty we assume no relevant search results
  const noSearchOrFilterResults = (query.search || query.filter !== FilterType.Empty) && displayList.length === 0;

  return (
    <>
      {noLeadLists && (
        <EmptyState
          type='no-items-1'
          text='No lead lists currently exist'
          subText='Create a lead list by clicking the button below'
          action={toggleCreateListModal}
          actionText='Create Lead List'
        />
      )}

      {!noLeadLists && (
        <Grid container spacing={1} alignContent='center'>
          <Grid item xs={12} md={3}>
            <TextField
              fullWidth
              variant='outlined'
              label='Search'
              id='search'
              name='search'
              defaultValue={query.search}
              onChange={onQueryChange}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Selectbox
              id='filter'
              name='filter'
              title='Filter'
              items={filterList}
              onChange={onQueryChange}
              defaultValue={query.filter}
            />
          </Grid>

          <Hidden smDown>
            <Grid item md={3}></Grid>
          </Hidden>

          {hasScope(AccessScope.CanCreateLeadsList) && (
            <Grid style={{ display: 'flex', alignItems: 'center' }} item xs={12} md={3}>
              <Button
                variant='contained'
                color='primary'
                disableElevation
                fullWidth
                startIcon={<AddIcon />}
                onClick={toggleCreateListModal}>
                Create Lead List
              </Button>
            </Grid>
          )}
          <Grid item xs={12}>
            {!noSearchOrFilterResults && <List>{displayList}</List>}
            {noSearchOrFilterResults && (
              <EmptyState
                type='no-records-found'
                text='No lead lists found matching your search criteria'
                subText='Try alternate words or selections.'
              />
            )}
          </Grid>
        </Grid>
      )}

      <CreateLeadListModal
        open={createModalOpen}
        submitting={createModalSubmitting}
        onAccept={addLeadList}
        onClose={toggleCreateListModal}
      />
    </>
  );
};

export default CampaignLeadLists;
