import Breadcrumbs from '@mui/material/Breadcrumbs';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';

import AsyncLoader from '~components/AsyncLoader';
import EmptyState from '~components/EmptyState';
import { TabPanel } from '~components/TabPanel';
import DiallerGroupLiveDash from '~pages/CampaignManagement/DiallerGroupDetails/DiallerGroupLiveDash';
import useDiallerGroup from '~pages/CampaignManagement/DiallerGroupDetails/useDiallerGroup';
import { DiallerType } from '~pages/CampaignManagement/domain';
import { useAuth } from '~providers/AuthProvider';
import { AccessScope } from '~providers/AuthProvider/domain';
import { useSetPageTitleProps } from '~providers/PageTitleProvider';
import Routes from '~providers/RouteProvider/Routes';

import DiallerGroupAgents from './DiallerGroupAgents';
import DiallerGroupCampaigns from './DiallerGroupCampaigns';
import DiallerGroupQueues from './DiallerGroupQueues';
import DiallerGroupSettings from './DiallerGroupSettings';

// Used to manage visible tab selection
enum ViewTabsType {
  Agents = 1,
  Campaigns,
  LiveDash,
  Settings,
  Queues,
}

const a11yProps = (index: number) => ({
  'id': `tab-${index}`,
  'aria-controls': `tab-${index}`,
});

const DiallerGroupDetails = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { hasScope } = useAuth();

  const setPageTitleProps = useSetPageTitleProps();
  const { diallerGroupId } = useParams() as { diallerGroupId: string };
  const { loading, data, error: diallerGroupError, update, archive, reload } = useDiallerGroup(+diallerGroupId);
  const [error, setError] = useState(diallerGroupError);

  const tabs: { name: string; value: ViewTabsType; enabled: boolean }[] = [
    {
      name: 'Agents',
      value: ViewTabsType.Agents,
      enabled: hasScope(AccessScope.CanViewGroupAgents),
    },
    {
      name: 'Campaigns',
      value: ViewTabsType.Campaigns,
      enabled: true,
    },
    {
      name: 'Live Dash',
      value: ViewTabsType.LiveDash,
      enabled: data !== undefined && data.diallerType == DiallerType.SIP,
    },
    {
      name: 'Settings',
      value: ViewTabsType.Settings,
      enabled: hasScope(AccessScope.CanViewDiallerGroupSettings),
    },
    {
      name: 'Queues',
      value: ViewTabsType.Queues,
      enabled: data !== undefined && data.diallerType == DiallerType.SIP,
    },
  ];

  const allowedTabs = tabs.filter((tab) => tab.enabled);

  // default tab, fallback to this tab when no permission on specified tab
  const defaultViewTab = allowedTabs.length > 0 ? allowedTabs[0].value : undefined;

  const initialLoad = loading && !data;
  const tabIndex = +ViewTabsType[searchParams.get('show') as any];

  useEffect(() => {
    // fallback to default one when no permission(can happen when show parameter in the URL is manually updated)
    if (!allowedTabs.find((i) => i.value == tabIndex)) {
      if (defaultViewTab) {
        setSearchParams({
          show: ViewTabsType[defaultViewTab],
        });
      } else {
        setError('You do not have permission to view this information');
      }
    }
  }, [allowedTabs, defaultViewTab]);

  useEffect(() => {
    reload();
  }, [tabIndex]);

  useEffect(() => {
    if (data) {
      // Set page title
      setPageTitleProps({ pageName: data.name });
    }
  }, [data]);

  const onTabChange = (e: ChangeEvent<{}>, tabIndex: number) => {
    setSearchParams({
      show: ViewTabsType[tabIndex],
    });
  };

  const errorAction = () => {
    navigate(Routes.diallerConfig.path);
  };

  const errorDisplay = error ? (
    <EmptyState
      type='error'
      text={error}
      subText='Click the button below to return to dialler configuration.'
      action={errorAction}
      actionText='Dialler Config'
    />
  ) : null;

  return (
    <AsyncLoader isLoading={initialLoad} error={errorDisplay}>
      <Breadcrumbs style={{ marginBottom: 16 }} aria-label='breadcrumb'>
        <Link underline='hover' color='inherit' component={RouterLink} to={Routes.diallerConfig.path}>
          Dialler Configuration
        </Link>
        <Typography color='textPrimary'>Dialler Group Details</Typography>
      </Breadcrumbs>

      {data === undefined && (
        <EmptyState
          type='not-found'
          text='Sorry!'
          subText='We are unable to find the page you are looking for. Click the button below to return to campaign details.'
          action={errorAction}
          actionText='Campaigns Details'
        />
      )}

      {data !== undefined && (
        <>
          <Typography variant='h4' component='h1' gutterBottom>
            {data.name}
          </Typography>

          <Tabs
            orientation='horizontal'
            variant='scrollable'
            onChange={onTabChange}
            value={tabIndex}
            indicatorColor='primary'
            aria-label='Vertical tabs example'>
            {allowedTabs.map(({ name, value }) => (
              <Tab key={value} label={name} {...a11yProps(value)} value={value} />
            ))}
          </Tabs>
          <Divider variant='fullWidth' component='hr' />

          <TabPanel value={tabIndex} index={ViewTabsType.Agents}>
            <DiallerGroupAgents />
          </TabPanel>

          <TabPanel value={tabIndex} index={ViewTabsType.Campaigns}>
            <DiallerGroupCampaigns diallerType={data.diallerType} campaignType={data.campaignType} />
          </TabPanel>

          <TabPanel value={tabIndex} index={ViewTabsType.LiveDash}>
            <DiallerGroupLiveDash diallerGroup={data} />
          </TabPanel>

          <TabPanel value={tabIndex} index={ViewTabsType.Settings}>
            <DiallerGroupSettings diallerGroup={data} update={update} archive={archive} />
          </TabPanel>

          <TabPanel value={tabIndex} index={ViewTabsType.Queues}>
            <DiallerGroupQueues diallerGroupId={diallerGroupId} />
          </TabPanel>
        </>
      )}
    </AsyncLoader>
  );
};

export default DiallerGroupDetails;
