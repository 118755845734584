import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import List from '@mui/material/List';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { DateTime } from 'luxon';
import React, { ChangeEvent, useMemo, useState } from 'react';
import { generatePath, useParams } from 'react-router-dom';

import AsyncLoader from '~components/AsyncLoader';
import { DotLoader } from '~components/DotLoader';
import EmptyState from '~components/EmptyState';
import useDebounce from '~hooks/useDebounce';
import CampaignCard from '~pages/CampaignManagement/CampaignList/CampaignCard';
import AssignCampaignModal from '~pages/CampaignManagement/DiallerGroupDetails/DiallerGroupCampaigns/AssignCampaignModal';
import EditAssignedCampaignModal from '~pages/CampaignManagement/DiallerGroupDetails/DiallerGroupCampaigns/EditAssignedCampaignModal';
import useDiallerGroupCampaignSearch from '~pages/CampaignManagement/DiallerGroupDetails/DiallerGroupCampaigns/useDiallerGroupCampaignSearch';
import {
  AddCampaignGroupAssignment,
  UpdateCampaignGroupAssignment,
} from '~pages/CampaignManagement/DiallerGroupDetails/DiallerGroupCampaigns/useDiallerGroupCampaignSearch/domain';
import { CampaignType, DiallerType } from '~pages/CampaignManagement/domain';
import { useAuth } from '~providers/AuthProvider';
import { AccessScope } from '~providers/AuthProvider/domain';
import { useNotification } from '~providers/NotificationProvider';
import Routes from '~providers/RouteProvider/Routes';
import { APIError, UnsupportedStructureError } from '~services/Errors';
import { parseBoolean } from '~utils/Functions';

// Active is archived false, Archived is archived true
const enum FilterType {
  None = '',
  Active = 'false',
  Archived = 'true',
}

interface Props {
  diallerType: DiallerType;
  campaignType: CampaignType;
}

interface Query {
  search: string;
  filter: FilterType;
}

const filterList = [
  {
    label: 'None',
    value: FilterType.None,
  },
  {
    label: 'Active',
    value: FilterType.Active,
  },
  {
    label: 'Archived',
    value: FilterType.Archived,
  },
];

const DiallerGroupCampaigns = ({ diallerType, campaignType }: Props) => {
  const { hasScope } = useAuth();
  const { pushNotification } = useNotification();
  const { diallerGroupId } = useParams() as { diallerGroupId: string };
  const [assignModalOpen, setAssignModalOpen] = useState<boolean>(false);
  const [submittingData, setSubmittingData] = useState<boolean>(false);
  const [query, setQuery] = useState<Query>({ search: '', filter: FilterType.Active });
  const [editableAssignedCampaignRef, setEditableAssignedCampaignRef] = useState<number | undefined>(undefined);
  const debouncedSearch = useDebounce(query.search, 500);
  const {
    loading,
    error,
    list,
    hasMore,
    add,
    update,
    remove,
    intersectionObserverRef: lastDataElement,
  } = useDiallerGroupCampaignSearch(+diallerGroupId, {
    search: debouncedSearch,
    archived: query.filter === FilterType.None ? undefined : parseBoolean(query.filter),
  });
  const editableAssignedCampaign = useMemo(() => {
    return list.find((item) => item.entryId === editableAssignedCampaignRef);
  }, [editableAssignedCampaignRef]);
  const noSearchOrFilterSet = query.search === '' && query.filter === '';

  const toggleAssignModal = () => {
    setAssignModalOpen((prev) => !prev);
  };

  const setEditAssignedCampaign = (entryId: number) => {
    setEditableAssignedCampaignRef(entryId);
  };

  const removeEditAssignedCampaign = () => {
    setEditableAssignedCampaignRef(undefined);
  };

  const onQueryChange = (e: ChangeEvent<any>) => {
    const { name, value } = e.target;
    setQuery((prev) => ({ ...prev, [name]: value }));
  };

  const assignCampaign = async (data: AddCampaignGroupAssignment) => {
    setSubmittingData(true);

    try {
      await add(data);
    } catch (e) {
      pushNotification('error', (e as APIError | UnsupportedStructureError).message);
      return;
    } finally {
      setSubmittingData(false);
    }

    pushNotification('success', 'Assigned campaign to dialler group');
    setAssignModalOpen(false);
  };

  const updateAssignedCampaign = async (data: UpdateCampaignGroupAssignment) => {
    setSubmittingData(true);

    try {
      await update(data);
    } catch (e) {
      pushNotification('error', (e as APIError | UnsupportedStructureError).message);
      return;
    } finally {
      setSubmittingData(false);
    }

    pushNotification('success', 'Updated campaign assigned to dialler group');
    setEditableAssignedCampaignRef(undefined);
  };

  const removeAssignedCampaign = async (entryId: number) => {
    try {
      await remove(entryId);
    } catch (e) {
      pushNotification('error', (e as APIError | UnsupportedStructureError).message);
      return;
    }

    pushNotification('success', `Removed campaign from dialler group`);
  };

  const displayList = list.map((dgc, index) => {
    let menuItems: any[] = [];
    if (hasScope(AccessScope.CanUpdateDiallerGroupCampaign)) {
      menuItems = [{ name: 'Edit', action: () => setEditAssignedCampaign(dgc.entryId) }];
    }
    if (hasScope(AccessScope.CanRemoveDiallerGroupCampaign)) {
      menuItems = [...menuItems, { name: 'Remove', action: () => removeAssignedCampaign(dgc.entryId) }];
    }
    let props = {
      ref: index === list.length - 1 ? lastDataElement : undefined,
      key: dgc.entryId,
      name: dgc.name,
      archived: Boolean(dgc.archived),
      to: generatePath(Routes.viewCampaign.path, { campaignId: dgc.campaignId.toString() }),
      isDefaultCampaign: dgc.isDefaultCampaign,
      menuItems: menuItems.length ? menuItems : undefined,
      statisticItems: [
        {
          text: `Start: ${dgc.startTime ? DateTime.fromISO(dgc.startTime).toFormat('h:mm a') : 'N/A'}`,
        },
        {
          text: `End: ${dgc.endTime ? DateTime.fromISO(dgc.endTime).toFormat('h:mm a') : 'N/A'}`,
        },
        { text: `Priority: ${dgc.priority}` },
      ],
    };

    return <CampaignCard {...props} />;
  });

  const filterListsDisplay = useMemo(
    () =>
      filterList.map((item, index) => (
        <MenuItem key={index} value={item.value}>
          {item.label}
        </MenuItem>
      )),
    [],
  );

  return (
    <>
      <Grid sx={{ marginBottom: 1 }} container spacing={1} alignContent='center'>
        <Grid item xs={12} md={3}>
          <TextField
            fullWidth
            variant='outlined'
            label='Search'
            id='search'
            name='search'
            defaultValue={query.search}
            onChange={onQueryChange}
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <TextField
            fullWidth
            select
            variant='outlined'
            id='filter'
            name='filter'
            label='Filter'
            value={query.filter}
            onChange={onQueryChange}>
            {filterListsDisplay}
          </TextField>
        </Grid>

        <Hidden smDown>
          <Grid item md={3}></Grid>
        </Hidden>

        {hasScope(AccessScope.CanAssignCampaignToGroup) && (
          <Grid style={{ display: 'flex', alignItems: 'center' }} item xs={12} md={3}>
            <Button
              variant='contained'
              color='primary'
              disableElevation
              fullWidth
              startIcon={<AddIcon />}
              onClick={toggleAssignModal}>
              Assign Campaign
            </Button>
          </Grid>
        )}
      </Grid>

      <AsyncLoader isLoading={loading && list.length === 0}>
        <Grid container spacing={1} alignContent='center'>
          <Grid item xs={12}>
            {list.length > 0 && (
              <>
                <List>{displayList}</List>
                {loading && list.length > 0 && <DotLoader align='center' />}

                {!loading && !hasMore && (
                  <Typography variant='body2' align='center' color='textSecondary'>
                    No more results to display
                  </Typography>
                )}

                {error && list.length > 0 && (
                  <Typography variant='body2' align='center' color='textSecondary'>
                    Failed to load agents
                  </Typography>
                )}
              </>
            )}

            {list.length === 0 && !noSearchOrFilterSet && (
              <EmptyState
                type='no-records-found'
                text='No campaigns found matching your search criteria'
                subText='Try alternate words or selections.'
              />
            )}

            {list.length === 0 && noSearchOrFilterSet && (
              <EmptyState type='no-items-3' text='No campaigns assigned to group' />
            )}
          </Grid>
        </Grid>

        <AssignCampaignModal
          open={assignModalOpen}
          diallerType={diallerType}
          campaignType={campaignType}
          submitting={submittingData}
          onClose={toggleAssignModal}
          onAccept={assignCampaign}
        />

        <EditAssignedCampaignModal
          open={Boolean(editableAssignedCampaign)}
          diallerGroupCampaign={editableAssignedCampaign}
          submitting={submittingData}
          onClose={removeEditAssignedCampaign}
          onAccept={updateAssignedCampaign}
        />
      </AsyncLoader>
    </>
  );
};

export default DiallerGroupCampaigns;
