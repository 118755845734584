import axios, { CancelTokenSource } from 'axios';

import { DiallerGroup, UpdateDiallerGroup } from '~pages/CampaignManagement/domain';
import { APIError, UnsupportedStructureError } from '~services/Errors';

import { DiallerGroupResponseDecoder } from './domain';

export const getDiallerGroup = async (
  diallerGroupId: number,
  cancelToken?: CancelTokenSource,
): Promise<DiallerGroup | undefined> => {
  const path = `/api/group/${diallerGroupId}`;
  let resp;

  try {
    resp = await axios.request({
      url: path,
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
      cancelToken: cancelToken ? cancelToken.token : undefined,
    });
  } catch (e) {
    if (axios.isCancel(e)) {
      return undefined;
    }

    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, (e.response!.data as { error: string }).error || e.message);
    }

    throw new APIError(-1, e as string);
  }

  const decoded = DiallerGroupResponseDecoder.run(resp.data);

  if (decoded.ok === false) {
    const err = new UnsupportedStructureError(decoded.error.message);

    console.error(decoded.error);
    console.error(err);
    throw err;
  }

  return decoded.result;
};

export const updateDiallerGroup = async (diallerGroupId: number, data: Partial<UpdateDiallerGroup>): Promise<void> => {
  const path = `/api/group/${diallerGroupId}`;

  let body: any = {
    name: data.name || undefined,
    description: data.description || undefined,
    default_campaign_id: data.defaultCampaignId || undefined,
    enable_manual_outbound: data.enableManualOutbound ?? undefined,
    manual_outbound_require_details: data.manualOutboundRequireDetails ?? undefined,
    enable_prepared_outbound_hangup: data.enablePreparedOutboundHangup ?? undefined,
    enable_manual_outbound_when_offline: data.enableManualOutboundWhenOffline ?? undefined,
    system_disposed_inbound_calls: data.systemDisposedInboundCalls,
    voicemail_message_arn: data.voicemailMessageARN || null,
    preview_settings: undefined,
    predictive_settings: undefined,
    campaigns: undefined,
  };

  if (data.previewSettings) {
    body = {
      ...body,
      preview_settings: {
        enable_endpoint_selection: data.previewSettings.enableEndpointSelection ?? undefined,
        push_preview_seconds: data.previewSettings.pushPreviewSeconds ?? undefined,
        ring_out_seconds: data.previewSettings.ringOutSeconds ?? undefined,
      },
    };
  }

  if (data.predictiveSettings) {
    body = {
      ...body,
      predictive_settings: {
        hold_drop_seconds: data.predictiveSettings.holdDropSeconds ?? undefined,
        max_lines_per_agent: data.predictiveSettings.maxLinesPerAgent ?? undefined,
        lookahead_time_seconds: data.predictiveSettings.lookaheadTimeSeconds ?? undefined,
        ring_time_seconds: data.predictiveSettings.ringTimeSeconds ?? undefined,
        num_reserved_agents: data.predictiveSettings.numReservedAgents ?? undefined,
        outbound_queue: data.predictiveSettings.outboundQueue,
      },
    };
  }

  try {
    await axios.request({
      method: 'PUT',
      url: path,
      data: body,
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, (e.response!.data as { error: string }).error || e.message);
    }

    throw new APIError(-1, e as string);
  }
};

export const archiveDiallerGroupById = async (diallerGroupId: number): Promise<void> => {
  const path = `/api/group/${diallerGroupId}`;

  try {
    await axios.request({
      method: 'DELETE',
      url: path,
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, (e.response!.data as { error: string }).error || e.message);
    }

    throw new APIError(-1, e as string);
  }
};
